import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Card, Skeleton, Button, Modal, Input, Select, message, Checkbox, Popconfirm, Icon } from "antd";
import { url, localPath } from "../../config";
import { userInfo } from "../../store/actions/authActions";
import $ from "jquery";
import placeholderImage from "../../images/image_placeholder.jpg";
import moment from "moment";
import ImageUploader from "../widgets/uploader/Uploader";
import _ from "lodash";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ProductDetails.scss";

const { Meta } = Card;
const { Option, OptGroup } = Select;
const { TextArea } = Input;


const ProductDetails = (props) => {

    const [product, setProduct] = useState([]);

    const user = {
        user_id: localStorage.getItem("uid"),
        product_id: props.match.params.id
    };

    const [inputs, setInputs] = useState({
        title: "",
        description: "",
        category: "",
        type: "",
        country: "",
        phone: "",
        id: "",
        price: "",
        image: "",
        phone_calls: "",
        text_sms: "",
        contact_preferences: "",
        phone_calls: false,
        text_sms: false
    });

    const [checkboxes, setCheckboxes] = useState({
        toggle_phone_checkboxes: false
    });

    const toggleInputPhone = () => {
        setCheckboxes({
            ...checkboxes,
            toggle_phone_checkboxes: !checkboxes.toggle_phone_checkboxes
        })
    }

    const inputHandler = (event) => {
        setInputs({
            ...inputs,
            [event.target.name]: event.target.value
        })
    }

    const selectHandler = (event, name) => {
        setInputs({
            ...inputs,
            [name]: event
        })
    }

    useEffect(() => {
        const token = localStorage.getItem("token");

        $.ajax({
            type: "POST",
            dataType: "json",
            url: `${url}/api/user/product`,
            data: JSON.stringify(user),
            headers: {
                'Content-Type': 'application/json',
                'authorization': 'Bearer ' + token
            },
            success: function (response) {
                setProduct(response.product);
                if (_.isEmpty(response.product)) {
                    props.history.push("/");
                }
            }
        });
    }, [setProduct])

    const [modal, setModal] = useState({ modalOpen: false });

    const [edited, setEdited] = useState({ productEdited: false });

    useEffect(() => {
        const token = localStorage.getItem("token");

        if (edited.productEdited) {
            $.ajax({
                type: "POST",
                dataType: "json",
                url: `${url}/api/user/product`,
                data: JSON.stringify(user),
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + token
                },
                success: function (response) {
                    setEdited({
                        ...edited,
                        productEdited: false
                    })
                    setProduct(response.product)
                }
            });
        }
    }, [edited])

    const toggleModal = () => {
        setModal({
            ...modal,
            modalOpen: !modal.modalOpen
        });

        setInputs({
            ...inputs,
            id: product.id
        })
    }

    const closeModal = () => {
        setModal({
            ...modal,
            modalOpen: false
        })
    }

    const uploaderFiles = (files, name) => {
        setInputs({
            ...inputs,
            [name]: files
        })
    };

    const successMessage = (success) => {
        message.config({
            top: 100,
            duration: 3
        });
        message.success(success);
    };

    const errorMessage = (error) => {
        message.config({
            top: 100,
            duration: 3
        });
        message.error(error);
    };

    console.log(product);

    const sendEditedData = (event) => {
        event.preventDefault();

        const editedData = inputs;
        const token = localStorage.getItem("token");
        
        if (inputs.phone.length !== 9 && inputs.phone !== "") {
            errorMessage("Phone number should contain 9 digits")
            return;
        }

        $.ajax({
            type: "POST",
            dataType: "json",
            url: `${url}/api/user/editproduct`,
            data: JSON.stringify(editedData),
            headers: {
                'Content-Type': 'application/json',
                'authorization': 'Bearer ' + token
            },
            success: function (response) {
                successMessage("Product edited successfully");
                setEdited({
                    ...edited,
                    productEdited: true
                })
            }
        });
    }


    const settings = {
        dots: true,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    }

    const [deleted, setDeleted] = useState({ isDeleted: false });

    useEffect(() => {
        const token = localStorage.getItem("token");

        if (deleted.isDeleted) {
            $.ajax({
                type: "GET",
                dataType: "json",
                url: `${url}/api/users/list?token=${token}`,
                success: function (response) {
                    setDeleted({
                        ...deleted,
                        isDeleted: false
                    });
                    const userData = response.data;
                    props.userInfo(userData);
                },
                error: function (error) {
                    console.log(error);
                }
            });

        }
    }, [deleted])

    const deleteListing = (event) => {
        const token = localStorage.getItem("token");

        const id = {
            id: props.match.params.id
        }

        $.ajax({
            type: "POST",
            dataType: "json",
            url: `${url}/api/user/deleteproduct`,
            data: JSON.stringify(id),
            headers: {
                'Content-Type': 'application/json',
                'authorization': 'Bearer ' + token
            },
            success: function (response) {
                setDeleted({
                    ...deleted,
                    isDeleted: true

                });
                successMessage("Listing deleted successfully !");
                setTimeout(() => {
                    props.history.push(`/`)
                }, 1500)
            }, error: function (err) {
                console.log(err);
            }
        });
    }

    let productDetails = <Skeleton active paragraph={{ rows: 10 }} />;
    let showSlider = null;
    if (!_.isEmpty(product)) {

        const productImages = _.map(product.images, (image, index) => {
            return (
                <div key={index}>
                    <img src={`${localPath}` + product.images[index]} width="100" height="100" style={{ width: "100%", height: "350px" }} alt={product.images[index]} />
                </div>
            )
        });

        if (!_.isEmpty(product.images)) {
            showSlider = (
                <Slider {...settings}>
                    {productImages}
                </Slider>
            )
        } else {
            showSlider = <>
                <img src={placeholderImage} alt="Jane Verde - Placeholder Image" style={{ width: "100%", height: "350px" }} />
            </>
        }


        productDetails = (
            <Card>
                <Meta
                    title={product.title}
                />
                <div>
                    {/* <Slider {...settings}>
                        {productImages}
                    </Slider> */}
                    {showSlider}
                    <ul className="product-details-overview">
                        <li>
                            <span>Description</span>: {product.description}
                        </li>
                        <li><span>Category</span>: {product.subcategory_id}</li>
                        <li><span>Type</span>: {product.type} </li>
                        <li><span>State</span>: {product.location}</li>
                        <li><span>Phone</span>: {product.phone}</li>
                        <li><span>Posting ID</span>: {product.id}</li>
                        <li><span>Date posted</span>: {moment(product.created_at).format("MMMM-DD-YYYY")}</li>
                        <li><span>Price</span>: <b>$</b>{product.price} </li>
                    </ul>
                </div>
                <div className="text-center">
                    <Button className="edit-listing" onClick={toggleModal}>
                        Edit listing
                    </Button>
                    <Popconfirm
                        onConfirm={(event) => deleteListing(event)}
                        title="Are you sure you want to delete this listing ？"
                        icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                    >
                        <Button style={{ color: "indianred" }}><Icon type="exclamation-circle" />Delete Listing</Button>
                    </Popconfirm>
                </div>
            </Card>
        )
    }

    if (!props.isAuthenticated) {
        return <Redirect to="/signin" />
    }

    const uploaderOptions = {
        buttonText: "Upload new images",
        singleImage: false
    }

    console.log(props);

    return (
        <>
            <div className="product-details-wrap">
                <div>
                    {productDetails}
                    <Modal
                        title={"Edit listing #" + product.id}
                        visible={modal.modalOpen}
                        onOk={(event) => sendEditedData(event)}
                        okText="Submit Posting"
                        cancelText="Close"
                        onCancel={closeModal}
                        zIndex={11}
                    >
                        <div className="edit-product-overview">
                            <div className="form-grp">
                                <label>Posting title: <span>(*)</span></label>
                                <Input
                                    name="title"
                                    type="text"
                                    onChange={(event) => inputHandler(event)}
                                    placeholder={product.title}
                                />
                            </div>

                            <input type="hidden" value={product.id} />

                            <div className="form-grp">
                                <label>Posting type: <span>(*)</span></label>
                                <Select required
                                    onChange={(event) => selectHandler(event, "type")}
                                    name="type"
                                    allowClear={true}
                                    defaultValue={product.type}
                                >
                                    <Option value="hemp">Hemp</Option>
                                    <Option value="cannabis">Cannabis</Option>
                                </Select>
                            </div>

                            <div className="form-grp">
                                <label>Posting category: <span>(*)</span></label>
                                <Select required onChange={(event) => selectHandler(event, "category")}
                                    name="category" allowClear={true} defaultValue={product.subcategory_id}>
                                    <OptGroup label="For Sale">
                                        <Option value="4296">Biomass</Option>
                                        <Option value="6581">Concentrates</Option>
                                        <Option value="4617">Retail Products</Option>
                                        <Option value="4537">Grow Equipment / Supplies</Option>
                                        <Option value="4184">Lab Equipment Supplies</Option>
                                        <Option value="2971">Promotional</Option>
                                        <Option value="2073">In Search Of</Option>
                                    </OptGroup>
                                    <OptGroup label="Vendor Listings">
                                        <Option value="2057">Farms</Option>
                                        <Option value="3331">Processing</Option>
                                        <Option value="8090">Concentrate Facilities</Option>
                                        <Option value="4112">Ag Processing Facilities</Option>
                                        <Option value="7856">Lab Testing</Option>
                                        <Option value="4764">Banking</Option>
                                        <Option value="9132">Insurance</Option>
                                        <Option value="2528">Web / Design</Option>
                                        <Option value="6000">Sales Brokers</Option>
                                        <Option value="1090">Consulting</Option>
                                        <Option value="7854">Equipment Manufacturers</Option>
                                        <Option value="3285">Telecom</Option>
                                        <Option value="9121">Labor</Option>
                                        <Option value="5777">Marketing / Advertising</Option>
                                        <Option value="4910">Attorney</Option>
                                        <Option value="8788">Logistics / Trucking</Option>
                                        <Option value="5981">Equipment Rental</Option>
                                        <Option value="4715">Points of Sale</Option>
                                        <Option value="4644">Other</Option>
                                    </OptGroup>
                                    <OptGroup label="Misc">
                                        <Option value="2572">Events / Promotional</Option>
                                        <Option value="6095">General</Option>
                                        <Option value="2724">Groups / Activities</Option>
                                        <Option value="3824">Groups / Clubs / Memberships</Option>
                                    </OptGroup>
                                    <OptGroup label="Business / Real Estate">
                                        <Option value="3668">Business For Sale</Option>
                                        <Option value="5266">Commercial For Rent</Option>
                                        <Option value="2152">Commercial For Sale</Option>
                                        <Option value="6150">Investment Opportunities</Option>
                                        <Option value="2340">Land For Sale</Option>
                                    </OptGroup>
                                    <OptGroup label="Jobs">
                                        <Option value="2350">Admin / Executive</Option>
                                        <Option value="4111">Agriculture</Option>
                                        <Option value="5911">Distribution</Option>
                                        <Option value="6509">Laboratory</Option>
                                        <Option value="6762">Other / General</Option>
                                        <Option value="3043">Processing</Option>
                                        <Option value="6999">Sales / Marketing</Option>
                                        <Option value="5191">All</Option>
                                    </OptGroup>
                                </Select>
                            </div>

                            <div className="form-grp">
                                <label>State: <span>(*)</span></label>
                                <Select required onChange={(event) => selectHandler(event, "country")}
                                    name="state" allowClear={true} defaultValue={_.upperFirst(product.location)}>
                                    <OptGroup label="All recreational cannabis states">
                                        <Option value="alaska">Alaska</Option>
                                        <Option value="california">California</Option>
                                        <Option value="colorado">Colorado</Option>
                                        <Option value="maine">Maine</Option>
                                        <Option value="michigan">Michigan</Option>
                                        <Option value="nevada">Nevada</Option>
                                        <Option value="oregon">Oregon</Option>
                                        <Option value="vermont">Vermont</Option>
                                        <Option value="washington">Washington</Option>
                                    </OptGroup>
                                    <OptGroup label="All medical / recreational cannabis states">
                                        <Option value="alabama">Alabama</Option>
                                        <Option value="arkansas">Arkansas</Option>
                                        <Option value="american_samoa">American Samoa</Option>
                                        <Option value="arizona">Arizona</Option>
                                        <Option value="connecticut">Connecticut</Option>
                                        <Option value="columbia_district">District of Columbia</Option>
                                        <Option value="delaware">Delaware</Option>
                                        <Option value="florida">Florida</Option>
                                        <Option value="georgia">Georgia</Option>
                                        <Option value="guam">Guam</Option>
                                        <Option value="hawaii">Hawaii</Option>
                                        <Option value="iowa">Iowa</Option>
                                        <Option value="idaho">Idaho</Option>
                                        <Option value="illinois">Illinois</Option>
                                        <Option value="indiana">Indiana</Option>
                                        <Option value="kansas">Kansas</Option>
                                        <Option value="kentucky">Kentucky</Option>
                                        <Option value="louisiana">Louisiana</Option>
                                        <Option value="massachusetts">Massachusetts</Option>
                                        <Option value="maryland">Maryland</Option>
                                        <Option value="minnesota">Minnesota</Option>
                                        <Option value="missouri">Missouri</Option>
                                        <Option value="mississippi">Mississippi</Option>
                                        <Option value="montana">Montana</Option>
                                        <Option value="north_carolina">North Carolina</Option>
                                        <Option value="north_dakota">North Dakota</Option>
                                        <Option value="nebraska">Nebraska</Option>
                                        <Option value="new_hampshire">New Hampshire</Option>
                                        <Option value="new_jersey">New Jersey</Option>
                                        <Option value="new_mexico">New Mexico</Option>
                                        <Option value="new_york">New York</Option>
                                        <Option value="ohio">Ohio</Option>
                                        <Option value="oklahoma">Oklahoma</Option>
                                        <Option value="pennsylvania">Pennsylvania</Option>
                                        <Option value="puerto_rico">Puerto Rico</Option>
                                        <Option value="rhode_island">Rhode Island</Option>
                                        <Option value="south_carolina">South Carolina</Option>
                                        <Option value="south_dakota">South Dakota</Option>
                                        <Option value="tennessee">Tennessee</Option>
                                        <Option value="texas">Texas</Option>
                                        <Option value="utah">Utah</Option>
                                        <Option value="virginia">Virginia</Option>
                                        <Option value="virgin_islands">Virgin Islands</Option>
                                        <Option value="wisconsin">Wisconsin</Option>
                                        <Option value="west_virginia">West Virginia</Option>
                                        <Option value="wyoming">Wyoming</Option>
                                    </OptGroup>
                                </Select>
                            </div>

                            <div className="form-grp" style={{ position: "relative" }}>
                                <label>Phone: <span>(Should contain digits. Optional)</span></label>
                                <input type="number"
                                    name="phone"
                                    onChange={(event) => inputHandler(event)}
                                    placeholder={product.phone}
                                />
                                {/* <div className="checkbox-phone-group">
                                    <Checkbox onChange={toggleInputPhone} checked={checkboxes.toggle_phone_checkboxes} name="show_number">Show my phone number</Checkbox>
                                    <Checkbox
                                        
                                        // onChange={(event) => this.checkboxHandler(event)}
                                        name="phone_calls"
                                        // checked={this.state.phone_calls}
                                    >Phone calls</Checkbox>
                                    <Checkbox
                                        
                                        // onChange={(event) => this.checkboxHandler(event)}
                                        name="text_sms"
                                        // checked={this.state.text_sms}
                                    >Text / SMS</Checkbox>
                                </div> */}
                            </div>

                            <div className="form-grp">
                                <label>Posting price <span>(*)</span></label>
                                <input type="number"
                                    name="price"
                                    onChange={(event) => inputHandler(event)}
                                    placeholder={product.price}
                                />
                            </div>

                            <div className="form-grp">
                                <label>Posting description <span>(*)</span></label>
                                <TextArea rows={8}
                                    onChange={(event) => inputHandler(event)}
                                    name="description"
                                    placeholder={product.description}
                                />
                            </div>


                            <ImageUploader
                                propOptions={uploaderOptions}
                                files={(files) => uploaderFiles(files, "image")}
                                key={edited.productEdited}
                            />
                        </div>
                    </Modal>
                </div>
            </div>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.authenticated
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        userInfo: (info) => dispatch(userInfo(info))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
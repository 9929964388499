import $ from "jquery";
import Cookies from "js-cookie";
import { url } from "../../config";

const cookieDomain = "jv.com"

export const signUp = (credentials) => {
    return (dispatch) => {
        dispatch({ type: "BUTTON_LOADING" });
        $.ajax({
            type: "POST",
            dataType: "json",
            url: `${url}/api/user/register`,
            data: credentials,
            success: function (response) {
                console.log(response);
                const token = response.data.auth_token;
                const user_id = response.data.id;

                Cookies.set('_main', user_id, { expires: 7, domain: cookieDomain });
                Cookies.set("_token", token, { expires: 7, domain: cookieDomain });

                localStorage.setItem('token', token);
                localStorage.setItem("uid", user_id);

                if (response.success) {
                    dispatch({ type: "SIGNUP_SUCCESS" });
                    setTimeout(() => {
                        window.location.assign(`${url}`);
                    }, 2500)
                }

                if (!response.success) {
                    dispatch({ type: "SIGNUP_ERROR", payload: response.data })
                }
            },
            error: function (error) {
                console.log(error)
            }
        });
    }
};

export const signIn = (credentials) => {

    return (dispatch) => {
        dispatch({ type: "BUTTON_LOADING" });
        $.ajax({
            type: "POST",
            dataType: "json",
            url: `${url}/api/user/login`,
            data: credentials,
            success: function (response) {
                console.log(response);
                const token = response.data.auth_token;
                const loginFail = response.success;
                const user_id = response.data.id;

                Cookies.set('_main', user_id, { expires: 7, domain: cookieDomain });
                Cookies.set("_token", token, { expires: 7, domain: cookieDomain });

                if (!loginFail) {
                    console.log(loginFail);
                    console.log(response.data);
                    dispatch({ type: "SIGNIN_ERROR", authError: response.data })
                }

                if (token !== undefined && token !== null) {
                    localStorage.setItem('token', token);
                    localStorage.setItem("uid", user_id);
                    dispatch({ type: "SIGNIN_SUCCESS" })
                }
            },
            error: function (error) { console.log(error); }
        });
    }
};

export const authError = () => {
    console.log("AUTH ERROR CLICKED")
    return (dispatch) => {
        dispatch({ type: "SIGNIN_ERROR" })
    }
};

export const logOut = (token) => {
    return (dispatch) => {
        dispatch({ type: "GLOBAL_LOADING" })
        $.ajax({
            type: "POST",
            dataType: "json",
            url: `${url}/api/user/logout`,
            data: { token: token },
            success: function (data) {
                localStorage.removeItem("token");
                localStorage.removeItem("uid");
                Cookies.remove("_main", { domain: cookieDomain });
                Cookies.remove("_token", { domain: cookieDomain });

                dispatch({ type: "SIGNOUT_SUCCESS", globalLoader: false })
            },
            error: function (error) { console.log(error); }
        });
    }
};

export const userInfo = (userInfo) => {
    return (dispatch) => {
        dispatch({
            type: "USER_LOGGED",
            payload: userInfo
        });
    }
};

export const authToken = () => {
    return (dispatch) => {
        dispatch({
            type: "AUTH_TOKEN",
            authenticated: false
        })
    }
}

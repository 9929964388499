import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { userInfo } from "../../../store/actions/authActions";
import { setProducts } from "../../../store/actions/productActions";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import $ from "jquery";
import {url} from "../../../config";

import _ from "lodash";
import Table from "../tables/Table";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginTop: "40px"
    },
}));

const ScrollableTabsButtonForce = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    let tabContent = "";

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    tabContent = _.map(props.tabs, (item, index) => {
        return tabContent = (
            <Tab key={index} label={item.tab_name} {...a11yProps(index)} />
        )
    });

    const [active, setActive] = useState({ active: false });
    const token = localStorage.getItem("token");
    const user_id = localStorage.getItem("uid");

    useEffect(() => {
        let mounted = true;

        if (active.active) {
            
            $.ajax({
                type: "POST",
                dataType: "json",
                url: `${url}/api/user/products`,
                data: JSON.stringify({ user_id: user_id }),
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + token
                },
                success: function(response){
                    const products = response.products;
                    props.setProducts(products);
                },
                error: function(error){ console.log(error); }
              });
        }

        return () => {
            mounted = false;
        }
    }, [active])

    const makeProductActive = (id) => {
        const token = localStorage.getItem("token");
        setActive({
            ...active,
            active: true
        });

        $.ajax({
            type: "POST",
            dataType: "json",
            url: `${url}/api/user/reactivatepost`,
            data: JSON.stringify({ product_id: id }),
            headers: {
                'Content-Type': 'application/json',
                'authorization': 'Bearer ' + token
            },
            success: function(data){
                setActive({
                    ...active,
                    active: false
                })
            },
            error: function(error){ console.log(error); }
          });
    }

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable force tabs example"
                >
                    {tabContent}
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Table products={props.products.active} type="View" />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Table products={props.products.expired} makeActive={makeProductActive} type="Re-activate" />
            </TabPanel>
            <TabPanel value={value} index={2}>
                
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Table products={props.products.favorite} makeActive={makeProductActive} type="Remove"  />
            </TabPanel>
        </div>
    );
}

const mapStateToProps = (state) => {
    console.log(state);
    return {
        products: state.products.products,
        userData: state.auth.userData
    }
}

const dispatchToProps = (dispatch) => {
    return {
        userInfo: (userData) => dispatch(userInfo(userData)),
        setProducts: (products) => dispatch(setProducts(products))
    }
}


export default connect(mapStateToProps, dispatchToProps)(ScrollableTabsButtonForce);
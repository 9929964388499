import React, { Component } from "react";
import "./Loader.scss";

class Wrapper extends Component {
    render() {
        return (
            <>
                <div className="loader-wrapper"  style={this.props.toggle ? {display: "block"} : {display: "none"}} key={this.props.toggle}>
                    <div className="loader">Loading...</div>
                </div>
            </>
        )
    }
}



export default Wrapper;
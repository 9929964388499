import React, { Component } from "react";
import _ from "lodash";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {compose} from "redux";
import { Card } from 'antd';
import { Input } from 'antd';
import { Button } from 'antd';
import { message } from "antd";
import { Skeleton } from "antd";
import { userInfo } from "../../store/actions/authActions";
import { userChanges } from "../../store/actions/userActions";
import { authToken } from "../../store/actions/authActions";
import { url } from "../../config";
import "./UserSettings.scss";
import $ from "jquery";


class UserSettings extends Component {

    state = {
        loading: false,
        uploader_reset: false,
        updatedMessage: "",
        user: {
            user_current_password: "",
            user_new_password: "",
            user_password_confirmation: "",
        }
    };

    componentDidMount() {
        const token = localStorage.getItem("token");
        const that = this;

        if (token) {
            $.ajax({
                type: "GET",
                dataType: "json",
                url: `${url}/api/users/list?token=${token}`,
                success: function (response) {
                    const userData = response.data;
                    that.props.userInfo(userData);
                },
                error: function (error) {
                    localStorage.removeItem("token");
                    that.props.authToken();
                }
            });
        }

    }

    componentDidUpdate(prevState, prevProps) {
        const token = localStorage.getItem("token");
        const that = this;

        if (token === null) {
            that.props.authToken();
            return <Redirect to="/signin" />
        }

        if (prevProps.updatedMessage !== this.state.updatedMessage) {
            $.ajax({
                type: "GET",
                dataType: "json",
                url: `${url}/api/users/list?token=${token}`,
                success: function (response) {
                    const userData = response.data;
                    that.props.userInfo(userData);

                    that.setState({
                        updatedMessage: "",
                        uploader_reset: true
                    })
                },
                error: function (error) {
                    localStorage.removeItem("token");
                    that.props.authToken();
                }
            });
        }
    }

    inputChangeHandler = (event) => {
        this.setState(prevState => ({
            user: {
                ...prevState["user"],
                [event.target.name]: event.target.value
            }
        }))

    };

    handleSelectChange = (value, name, type) => {
        this.setState(prevState => ({
            ["user"]: {
                ...prevState["user"],
                [type]: value
            }
        }))
    };

    onKeyUpHandler = (event) => {
        if (event.keyCode === 13) {
            this.signIn(event);
        }
    }

    userInformationHandler = (event) => {
        event.preventDefault();
        const that = this;
        const token = localStorage.getItem("token");

        that.setState({
            loading: true
        });

        const userInfo = {
            current_password: that.state.user.user_current_password,
            new_password: that.state.user.user_new_password,
            password_confirmation: that.state.user.user_password_confirmation
        };

        that.setState({
            loading: true
        });

        $.ajax({
            type: "POST",
            dataType: "json",
            url: `${url}/api/user/edit`,
            data: JSON.stringify(userInfo),
            headers: {
                'Content-Type': 'application/json',
                'authorization': 'Bearer ' + token
            },
            success: function (response) {
                console.log(response);
                
                that.setState({
                    loading: false,
                    updatedMessage: response.reason
                });

                if (response.status === "failed") {
                    console.log("failed")
                    that.errorMessage(response.reason)
                }

                if (response.status === "success") {
                    that.successMessage(response.reason);
                    setTimeout(() => {
                        that.props.history.push("/")
                    }, 2500)
                }
            },
            error: function (error) {
                console.log(error);
                this.setState({
                    loading: false
                });
            }
        });
    }

    renderSettings = (user) => {

        let userSettings = (
            <>
                <Skeleton avatar paragraph={{ rows: 4 }} active />
            </>
        );

        if (!_.isEmpty(user)) {
            return userSettings = (
                <>
                    <Card
                        className="user-settings-card user-edit"
                    >
                        <div className="user-settings-form">
                            <form onSubmit={(event) => this.userInformationHandler(event)}>
                                <div className="form-grp">
                                    <label>Enter current password</label>
                                    <Input type="password"
                                        allowClear={true}
                                        onKeyUp={(event) => this.onKeyUpHandler(event)}
                                        name="user_current_password" onChange={(event) => {
                                            event.persist();
                                            this.inputChangeHandler(event)
                                        }} />
                                </div>
                                <div className="form-grp">
                                    <label>Enter new password</label>
                                    <Input type="password"
                                        allowClear={true}
                                        onKeyUp={(event) => this.onKeyUpHandler(event)}
                                        name="user_new_password" onChange={(event) => {
                                            event.persist();
                                            this.inputChangeHandler(event)
                                        }} />
                                </div>
                                <div className="form-grp">
                                    <label>Confirm new password</label>
                                    <Input type="password"
                                        allowClear={true}
                                        onKeyUp={(event) => this.onKeyUpHandler(event)}
                                        name="user_password_confirmation" onChange={(event) => {
                                            event.persist();
                                            this.inputChangeHandler(event)
                                        }} />
                                </div>

                                <div className="form-grp text-center">
                                    <Button htmlType="submit" type="primary"
                                        loading={this.state.loading}>
                                        Change Password
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </Card>
                </>
            )
        }
        return userSettings;
    }

    successMessage = (success) => {
        message.config({
            top: 100,
            duration: 3
        });
        message.success(success);
    };

    errorMessage = (error) => {
        message.config({
            top: 100,
            duration: 3
        });
        message.error(error);
    };

    render() {

        if (!this.props.authenticated) {
            return <Redirect to="/signin" />
        }

        const user = this.props.userData;

        return (
            <div className="user-settings-wrapper">
                <h1>Change password</h1>
                {this.renderSettings(user)}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authenticated: state.auth.authenticated,
        userData: state.auth.userData
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        userChanges: (changes) => dispatch(userChanges(changes)),
        userInfo: (userData) => dispatch(userInfo(userData)),
        authToken: () => dispatch(authToken())
    }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(UserSettings);
import $ from "jquery";
import { url } from "../../config";

export const userChanges = (changes) => {
    return (dispatch) => {

        dispatch({
            type: "USER_CHANGES",
            payload: true
        })
    }
}

export const verificationStepOne = (credentials) => {
    return (dispatch) => {
        const token = localStorage.getItem("token");
        $.ajax({
            type: "POST",
            dataType: "json",
            url: `${url}/api/verificationstep1`,
            data: JSON.stringify(credentials),
            headers: {
                'Content-Type': 'application/json',
                'authorization': 'Bearer ' + token
            },
            success: function (data) {
                dispatch({ type: "VERIFICATION_STEP_ONE" })
            },
            // error: function (error) { dispatch({ type: "VERIFICATION_STEP_ONE" }) }
        });
    }
}

export const verificationAdded = () => {
    return (dispatch) => {
        dispatch({
            type: "ADD_VERIFICATION",
            verificationAdded: true
        })
    }
}

export const verificationRemoved = () => {
    console.log("removed");
    return (dispatch) => {
        dispatch({
            type: "REMOVE_VERIFICATION"
        })
    }
}





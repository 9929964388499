import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { Redirect } from "react-router-dom";
import { verificationStepOne } from "../../store/actions/userActions"
import { userInfo } from "../../store/actions/authActions";
import Countries from "../widgets/selectCountries/Countries";
import { message } from "antd";
import { Input } from 'antd';
import { Select } from 'antd';
import { Button } from 'antd';
import { url } from "../../config";
import "./Verification.scss";

const { Option } = Select;

const StepOne = (props) => {

    const [user, setUser] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem("token");
        let mounted = true;

        if (mounted) {
            $.ajax({
                type: "GET",
                dataType: "json",
                url: `${url}/api/users/list?token=${token}`,
                success: function (response) {
                    const userData = response.data;
                    props.userInfo(userData);
                    setUser(userData);
                },
                error: function (error) { console.log(error); }
            });
        }

        return () => {
            mounted = false;
        }

    }, [setUser]);


    const [form, formSubmission] = useState({ formSubmitted: false });

    useEffect(() => {
        const token = localStorage.getItem("token");
        let mounted = true;

        if (form.formSubmitted) {

            $.ajax({
                type: "GET",
                url: `${url}/api/users/list?token=${token}`,
                success: function (response) {
                    const userData = response.data;
                    props.userInfo(userData);
    
                    formSubmission({
                        ...form,
                        formSubmitted: false
                    })
                },
                error: function (error) {
                    console.log(error);
                }
            });
        };

        return () => {
            mounted = false;
        }
    }, [form])


    const [values, setValues] = useState({
        first_name: "",
        last_name: "",
        state: "",
        company_name: "",
        entity_type: "",
        phone: ""
    });

    const [loader, setLoader] = useState(false);

    if(props.userData.verification_step_1) {
        return <Redirect to="/" />
    }


    if (!props.userAuthenticated) {
        return <Redirect to="/signin" />
    }

    if (props.verification) {
        return <Redirect to="/verification" />
    }

    const handleInput = (event) => {
        event.preventDefault();
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    const handleSelectChange = (value, type) => {
        setValues({
            ...values,
            [type]: value
        })
    }

    const handleForm = (event) => {
        event.preventDefault();

        if (values.phone.length !== 9) {
            errorMessage("Phone number should contain 9 digits");
            return;
        }

        if (values.entity_type === "") {
            errorMessage("Type of entity shouldn't be empty");
            return;
        }

        successMessage();
        props.verificationStepOne(values);

        formSubmission({
            ...form,
            formSubmitted: true
        });

        setLoader({
            ...loader,
            loader: true
        })
    }

    const successMessage = () => {
        message.config({
            top: 50,
            duration: 5
        });
        message.success("Verification Step I - Submitted !");
    };

    const errorMessage = (text) => {
        message.config({
            top: 50,
            duration: 3
        });
        message.error(text);
    };

    const onKeyUpHandler = (event) => {
        if (event.keyCode === 13) {
            handleForm(event);
        }
    }

    return (
        <>
            <div className="step-one">
                <h1>Verification Step I</h1>

                <form onSubmit={(event) => handleForm(event)}>
                    <div className="step-one__wrapper">
                        <div className="form-grp">
                            <label>First name <span>(*)</span></label>
                            <Input required name="first_name"
                                onChange={(event) => handleInput(event)}
                                onKeyUp={(event) => onKeyUpHandler(event)}
                                value={values.first_name}
                                required
                            />
                        </div>

                        <div className="form-grp">
                            <label>Last name <span>(*)</span></label>
                            <Input required name="last_name"
                                onChange={(event) => handleInput(event)}
                                onKeyUp={(event) => onKeyUpHandler(event)}
                                value={values.last_name}
                                required
                            />
                        </div>

                        <div className="form-grp countries">
                            <label>State <span>(*)</span></label>
                            <Countries countries={(value) => handleSelectChange(value, "state")}
                                country={values.state}
                            />
                        </div>

                        <div className="form-grp">
                            <label>Company <span>(*)</span></label>
                            <Input required name="company_name"
                                onChange={(event) => handleInput(event)}
                                onKeyUp={(event) => onKeyUpHandler(event)}
                                value={values.company_name}
                                required
                            />
                        </div>

                        <div className="form-grp">
                            <label>Type of entity - check all that apply <span>(*)</span></label>
                            <Select required onChange={(value) => handleSelectChange(value, "entity_type")}
                                name="entity_type" allowClear={true} mode="multiple">
                                <Option value="farmer">Farmer</Option>
                                <Option value="processor">Processor</Option>
                                <Option value="manufacturer">Manufacturer</Option>
                                <Option value="broker_wholesaler">Broker / Wholesaler</Option>
                                <Option value="consultant">Consultant</Option>
                                <Option value="retail">Retail</Option>
                                <Option value="equipment_manufacturer"> Equipment Manufacturer</Option>
                                <Option value="other">Other</Option>
                            </Select>
                        </div>

                        <div className="form-grp product-price-input">
                            <label>Phone number: <span>(*)</span></label>
                            <b>☏</b>
                            <input type="number"
                                name="phone"
                                onChange={(event) => handleInput(event)}
                                onKeyUp={(event) => onKeyUpHandler(event)}
                                required
                            />
                        </div>

                        <div className="form-grp text-center">
                            <p className="mandatory">Fields marked as <span>(*)</span> are mandatory</p>
                            <Button htmlType="submit" type="primary" loading={loader}>
                                Submit
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        userAuthenticated: state.auth.authenticated,
        userData: state.auth.userData,
        verification: state.user.verificationStepOne,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        verificationStepOne: (credentials) => dispatch(verificationStepOne(credentials)),
        userInfo: (userData) => dispatch(userInfo(userData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StepOne);
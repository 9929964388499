import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { signUp, authError } from "../../store/actions/authActions";
import { message, Alert, Button, Icon } from "antd";
import logo from "../../images/main-logo.svg";
import {url} from "../../config";
import "./SignUp.scss";

class SignUp extends Component {

    state = {
        name: "",
        lastname: "",
        email: "",
        password: "",
        password_confirmation: ""
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.isCreated) {
            this.successMessage();
        }
    }

    onSubmitHandler = (event) => {
        event.preventDefault();
        this.props.signUp(this.state);

        // this.setState({
        //     name: "",
        //     lastname: "",
        //     email: "",
        //     password: "",
        //     password_confirmation: ""
        // })
    }

    onChangeHandler = (event) => {
        this.setState({
            [event.target.name]: event.target.value.trim()
        });
    }

    onKeyUpHandler = (event) => {
        if (event.keyCode === 13) {
            this.onSubmitHandler(event);
        }
    }

    successMessage = () => {
        message.config({
            top: 30,
            duration: 3
        });
        message.success("Your profile has been created. Check your e-mail to verify your account !");
    };

    alertMessage = () => {
        let alert = null;

        if (this.props.authErrors !== null && this.props.authErrors !== undefined) {
            alert = <Alert
                type="error"
                message={this.props.authErrors}
                style={{ marginBottom: "20px" }}
                banner={true} onClose={this.closeAlert}
                closable={true}
            />;
        } else if (this.props.authErrors === null || this.props.authErrors === undefined) {
            alert = null
        }

        return alert;
    };

    closeAlert = () => {
        this.props.authError();
    };

    render() {


        if (this.props.isAuthenticated) {
            return <Redirect to="/dashboard" />
        }

        return (
            <>
                <div className="text-center auth-logo">
                    <a href={url}><img src={logo} alt="Jane Verde - Main Logo" /></a>
                </div>
                <div className="form">
                    <h2><Icon type="user-add" style={{ marginRight: "15px", fontSize: "21px" }} />Sign Up</h2>
                    <form onSubmit={(event) => this.onSubmitHandler(event)}>
                        <div className="form-grp">
                            <label>First name <span>(*)</span></label>
                            <input
                                type="text"
                                name="name"
                                value={this.state.name}
                                onChange={(event) => this.onChangeHandler(event)}
                                onKeyUp={(event) => this.onKeyUpHandler(event)}
                                required
                            />
                        </div>
                        <div className="form-grp">
                            <label>Last name <span>(*)</span></label>
                            <input
                                type="text"
                                name="lastname"
                                value={this.state.lastname}
                                onChange={(event) => this.onChangeHandler(event)}
                                onKeyUp={(event) => this.onKeyUpHandler(event)}
                                required
                            />
                        </div>
                        <div className="form-grp">
                            <label>E-mail address <span>(*)</span></label>
                            <input
                                type="email"
                                name="email"
                                value={this.state.email}
                                onChange={(event) => this.onChangeHandler(event)}
                                onKeyUp={(event) => this.onKeyUpHandler(event)}
                                required
                            />
                        </div>
                        <div className="form-grp">
                            <label>Enter password <span>(*)</span></label>
                            <input
                                type="password"
                                name="password"
                                value={this.state.password}
                                onChange={(event) => this.onChangeHandler(event)}
                                onKeyUp={(event) => this.onKeyUpHandler(event)}
                                minLength="8"
                                required
                            />
                        </div>
                        <div className="form-grp">
                            <label>Confirm password <span>(*)</span></label>
                            <input
                                type="password"
                                name="password_confirmation"
                                value={this.state.password_confirmation}
                                onChange={(event) => this.onChangeHandler(event)}
                                onKeyUp={(event) => this.onKeyUpHandler(event)}
                                minLength="8"
                                required
                            />
                        </div>
                        <div className="form-grp text-center">
                            {this.alertMessage()}
                            <p className="mandatory">Fields marked as <span>(*)</span> are mandatory</p>
                            <Button
                                size="large"
                                htmlType="submit"
                                type="primary"
                                loading={this.props.iconIsLoading}
                            >
                                REGISTER
                            </Button>
                        </div>
                        <div className="form-grp text-center">
                            <p className="already-account">You already have an account, <Link to="/signin">click here</Link> </p>
                        </div>
                    </form>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.authenticated,
        authErrors: state.auth.authError,
        iconIsLoading: state.auth.loading,
        isCreated: state.auth.created
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signUp: (newUser) => dispatch(signUp(newUser)),
        authError: () => dispatch(authError())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import paypal from "../../images/paypal.png";
import logo from "../../images/main-logo.svg";
import { url, paypalLink } from "../../config";
import { Skeleton, Card, Icon, Spin } from 'antd';

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const VerificationPayment = (props) => {

    const { business_license, industrial_license, picture_i_d } = props.userData;

    console.log(props.userData);

    // Identity Card
    let pictureId = null;
    if (picture_i_d && picture_i_d.verified === 2) {
        pictureId =
            <li>
                <Icon type="check-circle" style={{
                    color: "darkgray",
                    fontWeight: "bold",
                    verticalAlign: "middle",
                    marginTop: "-3px",
                    marginRight: "5px"
                }} />Identity Card
                - <b>Pending</b>
            </li>
    } else if (picture_i_d && picture_i_d.verified === 1) {
        pictureId =
            <li><Icon type="check-circle" className="verification-icon" style={{ color: "#00cc00" }} />Identity Card
                - <b>Submitted</b>
            </li>
    } else if (picture_i_d && picture_i_d.verified === -10) {
        pictureId =
            <li>
                <Icon type="close-circle" className="verification-icon" style={{ color: "indianred" }} />Identity Card
                - <b>Not Submitted</b>
            </li>
    } else if (picture_i_d && picture_i_d.verified === -1) {
        pictureId =
            <li>
                <Icon type="close-circle" className="verification-icon" style={{ color: "indianred" }} />Identity Card
                - <b>Refused</b>
            </li>
    }

    // Business License
    let businessLicense = null;
    if (business_license && business_license.verified === 2) {
        businessLicense =
            <li>
                <Icon type="check-circle" style={{
                    color: "darkgray",
                    fontWeight: "bold",
                    verticalAlign: "middle",
                    marginTop: "-3px",
                    marginRight: "5px"
                }} />Business License
                - <b>Pending</b>
            </li>
    } else if (business_license && business_license.verified === 1) {
        businessLicense =
            <li><Icon type="check-circle" className="verification-icon" style={{ color: "#00cc00" }} />Business License
                - <b>Submitted</b>
            </li>
    } else if (business_license && business_license.verified === -10) {
        businessLicense =
            <li>
                <Icon type="close-circle" className="verification-icon" style={{ color: "indianred" }} />Business License
                - <b>Not Submitted</b>
            </li>
    } else if (business_license && business_license.verified === -1) {
        businessLicense =
            <li>
                <Icon type="close-circle" className="verification-icon" style={{ color: "indianred" }} />Business License
                - <b>Refused</b>
            </li>
    }

    // Industrial License
    let industrialLicense = null;
    if (industrial_license && industrial_license.verified === 2) {
        industrialLicense =
            <li>
                <Icon type="check-circle" style={{
                    color: "darkgray",
                    fontWeight: "bold",
                    verticalAlign: "middle",
                    marginTop: "-3px",
                    marginRight: "5px"
                }} />Industrial License
                - <b>Pending</b>
            </li>
    } else if (industrial_license && industrial_license.verified === 1) {
        industrialLicense =
            <li><Icon type="check-circle" className="verification-icon" style={{ color: "#00cc00" }} />Industrial License
                - <b>Submitted</b>
            </li>
    } else if (industrial_license && industrial_license.verified === -10) {
        industrialLicense =
            <li>
                <Icon type="close-circle" className="verification-icon" style={{ color: "indianred" }} />Industrial License
                - <b>Not Submitted</b>
            </li>
    } else if (industrial_license && industrial_license.verified === -1) {
        industrialLicense =
            <li>
                <Icon type="close-circle" className="verification-icon" style={{ color: "indianred" }} />Industrial License
                - <b>Refused</b>
            </li>
    }

    // Subscription Amount

    let subscriptionAmount = "";
    if (!_.isEmpty(props.userData)) {
        if (industrial_license.verified === 1 && business_license.verified === 1 && picture_i_d.verified === 1) {
            subscriptionAmount = <h4 style={{ color: "#00cc00", border: "3px solid #00cc00" }}><b>Total</b>: $ 0.00 </h4>
        } else {
            subscriptionAmount =
                <h4 style={{ color: "indianred", border: "3px solid indianred" }}><b>Total</b>: $ 9.99 </h4>
        }
    }

    const renderPaymentButton = (data) => {
        let paymentButton = <Spin indicator={antIcon} />;

        if (!_.isEmpty(data)) {
            let url = `${paypalLink}/paypal/express-checkout?id=${data.id}`;

            if (data.industrial_license.verified === 1 && data.business_license.verified === 1 && data.picture_i_d.verified === 1) {
                console.log(1);
                paymentButton = null;
            } else if (data.subscription.active !== -10) {
                console.log(2);
                paymentButton = null;
            } else {
                console.log(3);
                return paymentButton = (<a href={url} name="submit">
                    Proceed to <img src={paypal} alt="Jane Verde - Pay Pal logo" />
                </a>)
            }
        }

        return paymentButton;
    };

    return (
        <>
            <div className="pay-pal-wrapper">
                <h1>Monthly Subscription (Step 3)</h1>
                <div className="pay-pal-card">
                    <Card loading={props.userData.length !== 0 ? false : true}>
                        <h3>Subscription summary:</h3>
                        <ul>
                            {pictureId}
                            {businessLicense}
                            {industrialLicense}
                        </ul>
                        <div className="text-center">
                            {subscriptionAmount}
                            <p>
                                <span>(!) </span>
                                Note: Submit Industrial License, Business License and
                                Identity Card to avoid monthly subscription of <span>$ 9.99 per</span> / month
                            </p>
                        </div>
                    </Card>
                </div>

                <div className="proceed-to-payment">
                    {renderPaymentButton(props.userData)}
                </div>
            </div>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        userData: state.auth.userData
    }
};

export default connect(mapStateToProps, null)(VerificationPayment);
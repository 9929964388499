import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { userInfo, authToken } from "../../store/actions/authActions";
import { setProducts } from '../../store/actions/productActions';
import moment from "moment";
import { Icon, Divider, Skeleton, Spin, Card, Popconfirm } from "antd";
import Listings from "../listings//Listings";
import _ from "lodash";
import { url } from "../../config";
import "./Dashboard.scss";
import $ from "jquery";

class Dashboard extends Component {

    state = {
        name: "",
        token: "",
        products: [],
        userData: []
    };

    componentDidMount() {
        const token = localStorage.getItem("token");
        const user_id = localStorage.getItem("uid");

        var that = this;

        if (token !== null) {
            $.ajax({
                type: "GET",
                dataType: "json",
                url: `${url}/api/users/list?token=${token}`,
                success: function (response) {
                    const userData = response.data;
                    that.props.userInfo(userData);
                },
                error: function (error) {
                    localStorage.removeItem("token");
                    that.props.authToken();
                }
            });

            $.ajax({
                type: "POST",
                url: `${url}/api/user/products`,
                dataType: "json",
                data: JSON.stringify({ user_id: user_id }),
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + token
                },
                success: function (response) {
                    that.props.setProducts(response.products);
                },
                error: function (error) {
                    console.log(error);
                }
            });
        }


    }

    componentDidUpdate() {
        const token = localStorage.getItem("token");

        if (token === null) {
            this.props.authToken();
            return <Redirect to="/signin" />
        }
    }

    businessLicense = (data) => {
        let businessLicense = (
            <Card title="Business License" bordered={true} hoverable={true} loading={false}>
                <Skeleton loading={true} active paragraph={{ rows: 0 }} />
            </Card>
        );

        if (!_.isEmpty(data)) {
            if (data.business_license.verified === -10) {
                return businessLicense = (
                    <Card title="Business License" bordered={true} hoverable={true} loading={false}>
                        <Icon type="minus-circle" style={{
                            color: "darkgray",
                            fontSize: "22px",
                            fontWeight: "bold",
                            verticalAlign: "middle",
                            marginTop: "-3px",
                            marginRight: "5px"
                        }} /> Verification Status: <span>Submit your license</span>
                    </Card>);
            }

            switch (data.business_license.verified) {
                case -1:
                    return businessLicense = (
                        <Card title="Business License" bordered={true} hoverable={true} loading={false}>
                            <Icon type="close-circle" style={{
                                color: "indianred",
                                fontSize: "22px",
                                fontWeight: "bold",
                                verticalAlign: "middle",
                                marginTop: "-3px",
                                marginRight: "5px"
                            }} /> Verification Status: <span>Refused</span>
                        </Card>);
                case 0:
                    return businessLicense = (
                        <Card title="Business License" bordered={true} hoverable={true} loading={false}>
                            <Icon type="minus-circle" style={{
                                color: "darkgray",
                                fontSize: "22px",
                                fontWeight: "bold",
                                verticalAlign: "middle",
                                marginTop: "-3px",
                                marginRight: "5px"
                            }} /> Verification Status: <span>Submit your license</span>
                        </Card>);
                case 1:
                    return businessLicense = (
                        <Card title="Business License" bordered={true} hoverable={true} loading={false}>
                            <Icon type="check-circle" style={{
                                color: "#00cc00",
                                fontSize: "22px",
                                fontWeight: "bold",
                                verticalAlign: "middle",
                                marginTop: "-3px",
                                marginRight: "5px"
                            }} /> Verification Status: <span>Approved</span>
                        </Card>);
                case 2:
                    return businessLicense = (
                        <Card title="Business License" bordered={true} hoverable={true} loading={false}>
                            <Icon type="check-circle" style={{
                                color: "darkgray",
                                fontSize: "22px",
                                fontWeight: "bold",
                                verticalAlign: "middle",
                                marginTop: "-3px",
                                marginRight: "5px"
                            }} /> Verification Status: <span>Pending</span>
                        </Card>);

                default:
                    return null

            }
        }
        return businessLicense;
    }

    industrialLicense = (data) => {
        let industrialLicense = (
            <Card title="Industrial License" bordered={true} hoverable={true} loading={false}>
                <Skeleton loading={true} active paragraph={{ rows: 0 }} />
            </Card>
        );

        if (!_.isEmpty(data)) {
            if (data.industrial_license.verified === -10) {
                return industrialLicense = (
                    <Card title="Industrial License" bordered={true} hoverable={true} loading={false}>
                        <Icon type="minus-circle" style={{
                            color: "darkgray",
                            fontSize: "22px",
                            fontWeight: "bold",
                            verticalAlign: "middle",
                            marginTop: "-3px",
                            marginRight: "5px"
                        }} /> Verification Status: <span>Submit your license</span>
                    </Card>);
            }

            switch (data.industrial_license.verified) {
                case -1:
                    return industrialLicense = (
                        <Card title="Industrial License" bordered={true} hoverable={true} loading={false}>
                            <Icon type="close-circle" style={{
                                color: "indianred",
                                fontSize: "22px",
                                fontWeight: "bold",
                                verticalAlign: "middle",
                                marginTop: "-3px",
                                marginRight: "5px"
                            }} /> Verification Status: <span>Refused</span>
                        </Card>);
                case 0:
                    return industrialLicense = (
                        <Card title="Industrial License" bordered={true} hoverable={true} loading={false}>
                            <Icon type="minus-circle" style={{
                                color: "darkgray",
                                fontSize: "22px",
                                fontWeight: "bold",
                                verticalAlign: "middle",
                                marginTop: "-3px",
                                marginRight: "5px"
                            }} /> Verification Status: <span>Submit your license</span>
                        </Card>);
                case 1:
                    return industrialLicense = (
                        <Card title="Industrial License" bordered={true} hoverable={true} loading={false}>
                            <Icon type="check-circle" style={{
                                color: "#00cc00",
                                fontSize: "22px",
                                fontWeight: "bold",
                                verticalAlign: "middle",
                                marginTop: "-3px",
                                marginRight: "5px"
                            }} /> Verification Status: <span>Approved</span>
                        </Card>);
                case 2:
                    return industrialLicense = (
                        <Card title="Industrial License" bordered={true} hoverable={true} loading={false}>
                            <Icon type="check-circle" style={{
                                color: "darkgray",
                                fontSize: "22px",
                                fontWeight: "bold",
                                verticalAlign: "middle",
                                marginTop: "-3px",
                                marginRight: "5px"
                            }} /> Verification Status: <span>Pending</span>
                        </Card>);

                default:
                    return null

            }
        }

        return industrialLicense;
    };


    identityCard = (data) => {
        let identityCard = (
            <Card title="Identity Card" bordered={true} hoverable={true} loading={false}>
                <Skeleton loading={true} active paragraph={{ rows: 0 }} />
            </Card>
        );

        if (!_.isEmpty(data)) {
            if (data.picture_i_d.verified === -10) {
                return identityCard = (
                    <Card title="Identity Card" bordered={true} hoverable={true} loading={false}>
                        <Icon type="minus-circle" style={{
                            color: "darkgray",
                            fontSize: "22px",
                            fontWeight: "bold",
                            verticalAlign: "middle",
                            marginTop: "-3px",
                            marginRight: "5px"
                        }} /> Verification Status: <span>Submit your license</span>
                    </Card>);
            }

            switch (data.picture_i_d.verified) {
                case -1:
                    return identityCard = (
                        <Card title="Identity Card" bordered={true} hoverable={true} loading={false}>
                            <Icon type="close-circle" style={{
                                color: "indianred",
                                fontSize: "22px",
                                fontWeight: "bold",
                                verticalAlign: "middle",
                                marginTop: "-3px",
                                marginRight: "5px"
                            }} /> Verification Status: <span>Refused</span>
                        </Card>);
                case 0:
                    return identityCard = (
                        <Card title="Identity Card" bordered={true} hoverable={true} loading={false}>
                            <Icon type="minus-circle" style={{
                                color: "darkgray",
                                fontSize: "22px",
                                fontWeight: "bold",
                                verticalAlign: "middle",
                                marginTop: "-3px",
                                marginRight: "5px"
                            }} /> Verification Status: <span>Submit your license</span>
                        </Card>);
                case 1:
                    return identityCard = (
                        <Card title="Identity Card" bordered={true} hoverable={true} loading={false}>
                            <Icon type="check-circle" style={{
                                color: "#00cc00",
                                fontSize: "22px",
                                fontWeight: "bold",
                                verticalAlign: "middle",
                                marginTop: "-3px",
                                marginRight: "5px"
                            }} /> Verification Status: <span>Approved</span>
                        </Card>);
                case 2:
                    return identityCard = (
                        <Card title="Identity Card" bordered={true} hoverable={true} loading={false}>
                            <Icon type="check-circle" style={{
                                color: "darkgray",
                                fontSize: "22px",
                                fontWeight: "bold",
                                verticalAlign: "middle",
                                marginTop: "-3px",
                                marginRight: "5px"
                            }} /> Verification Status: <span>Pending</span>
                        </Card>);

                default:
                    return null

            }
        }

        return identityCard;
    };

    renderPaymentStatus = (data) => {
        let paymentStatus = (
            <Card title="Subscription" bordered={true} hoverable={true} loading={false}>
                <Skeleton loading={true} active paragraph={{ rows: 0 }} />
            </Card>
        );

        if (!_.isEmpty(data)) {
            let ends_at = moment(data.subscription.created_at).add(1, "months").format("DD-MMM-YYYY");

            if (data.subscription.active === 1) {
                paymentStatus = (
                    <Card title="Subscription" bordered={true} hoverable={true} loading={false}>
                        <Icon type="check-circle" style={{
                            color: "#00cc00",
                            fontSize: "22px",
                            fontWeight: "bold",
                            verticalAlign: "middle",
                            marginTop: "-3px",
                            marginRight: "5px"
                        }} /> Subscription Status: <span>Submitted - (Active till: {ends_at})</span>
                    </Card>
                )
            }

            if (data.subscription.active === 0 || data.subscription.active === -10) {
                paymentStatus = (
                    <Card title="Subscription" bordered={true} hoverable={true} loading={false}>
                        <Icon type="minus-circle" style={{
                            color: "darkgray",
                            fontSize: "22px",
                            fontWeight: "bold",
                            verticalAlign: "middle",
                            marginTop: "-3px",
                            marginRight: "5px"
                        }} /> Subscription Status: <span>Not submitted</span>
                    </Card>
                )
            }

            if (data.business_license.verified === 1 && data.industrial_license.verified === 1 && data.picture_i_d.verified === 1) {
                paymentStatus = (
                    <Card title="Subscription" bordered={true} hoverable={true} loading={false}>
                        <Icon type="check-circle" style={{
                            color: "#00cc00",
                            fontSize: "22px",
                            fontWeight: "bold",
                            verticalAlign: "middle",
                            marginTop: "-3px",
                            marginRight: "5px"
                        }} /> Subscription Status: <span>Active</span>
                    </Card>
                )
            }

        }

        return paymentStatus;
    };

    renderLicenseStatus = (data) => {
        let licenseWrapper = <Skeleton active />;

        if (!_.isEmpty(data)) {
            licenseWrapper = (
                <div className="user-verification-info">
                    <div className="user-verification-info__box">
                        {this.businessLicense(this.props.userData)}
                    </div>
                    <div className="user-verification-info__box">
                        {this.industrialLicense(this.props.userData)}
                    </div>

                    <div className="user-verification-info__box">
                        {this.identityCard(this.props.userData)}
                    </div>
                    <div className="user-verification-info__box">
                        {this.renderPaymentStatus(this.props.userData)}
                    </div>
                </div>
            )
        }

        return licenseWrapper;
    };

    deleteAccount = () => {
        return (
            <Popconfirm
                title="Are you sure you want to delete your account ？"
                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
            >
                <a href="#" style={{color: "indianred"}}>Deactivate Account !</a>
            </Popconfirm>
        )
    }

    render() {

        if (!this.props.authenticated) {
            return <Redirect to="/signin" />
        }

        const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
        let verifyStep = null;

        if (this.props.userData) {
            if (this.props.userData.verification_step_1 === 0) {
                verifyStep = <Link to="/verification-step">Please verify your account</Link>
            } else {
                verifyStep = <Link to="/verification">Submit licenses / Identity Card</Link>
            }
        }

        let userAccountSettings = <Skeleton active />;
        if (!_.isEmpty(this.props.userData)) {
            userAccountSettings = (
                <ul>
                    <li><Icon type="mail" />Email address: {this.props.userData ? this.props.userData.email :
                        <Spin indicator={antIcon} />}</li>
                    <li><Icon type="lock" />Password: ******* ( <Link to="/settings">change password</Link> )</li>
                    <li><Icon type="safety-certificate" />Verification Status: {verifyStep}</li>
                    <li style={{color: "indianred"}}><Icon type="exclamation-circle" />{this.deleteAccount()}</li>
                </ul>
            )
        }

        return (
            <div className="dashboard-wrapper">
                <Listings verification={this.props.userData} />
                <Divider>Verification Status</Divider>
                {this.renderLicenseStatus(this.props.userData)}
                <Divider>Account Settings</Divider>
                <div className="user-account-setting">
                    {userAccountSettings}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authenticated: state.auth.authenticated,
        userData: state.auth.userData
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        userInfo: (info) => dispatch(userInfo(info)),
        authToken: () => dispatch(authToken()),
        setProducts: (products) => dispatch(setProducts(products))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
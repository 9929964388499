import React, { useState } from "react";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import logo from "../../images/main-logo.svg"
import { Button, Icon, message } from "antd";
import { url } from "../../config";
import $ from "jquery";
import "./SignIn.scss";


const ForgotPassword = (props) => {

    const [input, setInput] = useState({ email: "" });
    const [loader, setLoader] = useState({ status: false })

    const onChangeHandler = (event) => {
        setInput({
            ...input,
            [event.target.name]: event.target.value
        })
    }

    const successMessage = () => {
        message.config({
            top: 100,
            duration: 5
        });
        message.success("A request to reset your password was sent to your e-mail !");
    };

    const changePassword = (event) => {
        event.preventDefault();
        setLoader({
            ...loader,
            status: true
        });

        const user_email = { email: input.email }
        $.ajax({
            type: "POST",
            dataType: "json",
            url: `${url}/api/user/forgotPassword`,
            data: user_email,
            success: function (response) {
                console.log(response);
                successMessage();
                setLoader({
                    ...loader,
                    status: false
                });
                setInput({
                    ...loader,
                    email: ""
                });
            },
            error: function (error) { console.log(error); }
        });
    }

    if (props.isAuthenticated) {
        return <Redirect to="/" />
    }

    return (
        <>
            <div className="text-center auth-logo">
                <a href={url}><img src={logo} alt="Jane Verde - Main Logo" /></a>
            </div>
            <div className="form">
                <h2><Icon type="lock" style={{ marginRight: "15px", fontSize: "21px" }} />Forgot Password ?</h2>
                <form onSubmit={(event) => changePassword(event)}>
                    <div className="form-grp">
                        <label>E-mail address</label>
                        <input
                            type="email"
                            name="email"
                            value={input.email}
                            onChange={(event) => onChangeHandler(event)}
                            required
                        />
                    </div>

                    <div className="form-grp text-center">
                        <p className="mandatory">
                            Forgot your password? Submit your e-mail and we will send you an e-mail with your new password.
                    </p>
                        <Button
                            size="large"
                            htmlType="submit"
                            type="primary"
                            loading={loader.status}
                        >
                            Reset my Password
                    </Button>
                    </div>
                    <div className="form-grp text-center">
                        <p className="already-account">Back to <Link to="/signin">Sign In</Link></p>
                    </div>
                </form>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.authenticated
    }
}

export default connect(mapStateToProps, null)(ForgotPassword);
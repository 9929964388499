import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { userInfo } from "../../store/actions/authActions";
import { verificationAdded } from "../../store/actions/userActions";
import { verificationRemoved } from "../../store/actions/userActions";
import { DatePicker } from "antd";
import $ from "jquery";
import _ from "lodash";
import moment from "moment";
import { message } from "antd";
import { Input, Button, Card, Skeleton, Icon } from 'antd';
import Uploader from "../widgets/uploader/Uploader";
import { url } from "../../config";
import "./Verification.scss";

class Verification extends Component {

    state = {
        index: 1,
        fixedIndex: 1,
        inverseIndex: 1,
        loading_industrial: false,
        loading_business: false,
        loading_identification: false,
        loading_additional: false,
        uploader_reset: false,
        business: {
            business_license_number: "",
            business_expiration_date: "",
            business_image: ""
        },
        industrial: {
            business_license_number: "",
            business_expiration_date: "",
            industrial_image: ""
        },
        identification: {
            identification_expiration_date: "",
            identification_image: ''

        },
        additional: {
            additional_image: ""
        },
        pictures: [],
        verification_business: false,
        verification_industrial: false,
        verification_identification: false
    };

    componentDidMount() {
        const token = localStorage.getItem("token");
        const that = this;

        if (token) {
            $.ajax({
                type: "GET",
                dataType: "json",
                url: `${url}/api/users/list?token=${token}`,
                success: function (response) {
                    const userData = response.data;
                    that.props.userInfo(userData);
                },
                error: function (error) {
                }
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const token = localStorage.getItem("token");
        const that = this;

        if (token && (prevState.verification_business !== this.state.verification_business)) {
            $.ajax({
                type: "GET",
                dataType: "json",
                url: `${url}/api/users/list?token=${token}`,
                success: function (response) {
                    const userData = response.data;
                    that.props.userInfo(userData);
                    that.setState({
                        verification_business: false
                    })
                },
                error: function (error) {
                    console.log(error);
                }
            });
        }

        if (token && (prevState.verification_industrial !== this.state.verification_industrial)) {
            $.ajax({
                type: "GET",
                dataType: "json",
                url: `${url}/api/users/list?token=${token}`,
                success: function (response) {
                    const userData = response.data;
                    that.props.userInfo(userData);
                    that.setState({
                        verification_industrial: false
                    })
                },
                error: function (error) {
                    console.log(error);
                }
            });
        }

        if (token && (prevState.verification_identification !== this.state.verification_identification)) {
            $.ajax({
                type: "GET",
                dataType: "json",
                url: `${url}/api/users/list?token=${token}`,
                success: function (response) {
                    const userData = response.data;
                    that.props.userInfo(userData);
                    that.setState({
                        verification_identification: false
                    })
                },
                error: function (error) {
                    console.log(error);
                }
            });
        }


    }


    handleSelectChange = (event, name, type) => {
        this.setState(prevState => ({
            [type]: {
                ...prevState[type],
                [`${type}_country`]: event
            }
        }))
    };

    // Handle all the INPUTS
    handleInput = (event, name) => {
        this.setState(prevState => ({
            [name]: {
                ...prevState[name],
                [event.target.name]: event.target.value

            }
        }))
    };

    handleCalendar = (date, name) => {
        const submitDate = moment(date).format("YYYY-MM-DD");

        this.setState(prevState => ({
            [name]: {
                ...prevState[name],
                [`${name}_expiration_date`]: submitDate
            }
        }))
    };

    // Submit FORM
    handleForm = (event, name, route) => {
        event.preventDefault();
        const that = this;

        this.setState({
            [`loading_${name}`]: true
        });

        const token = localStorage.getItem("token");

        const licenseData = {
            [`${name}_license_number`]: this.state[name][`${name}_license_number`],
            [`${name}_expiration_date`]: this.state[name][`${name}_expiration_date`],
            [`${name}_image`]: this.state[name][`${name}_image`]
        };


        if (this.state[name][`${name}_license_number`] === "") {
            that.errorMessage("All fields are required !");
            this.setState({
                [`loading_${name}`]: false
            });
            return
        }

        if (this.state[name][`${name}_expiration_date`] === "" || this.state[name][`${name}_expiration_date`] === undefined) {
            that.errorMessage("All fields are required !");
            this.setState({
                [`loading_${name}`]: false
            });
            return
        }

        if (this.state[name][`${name}_image`].length > 3 || this.state[name][`${name}_image`].length < 1) {
            that.errorMessage("Upload at least 1 image !")
            this.setState({
                [`loading_${name}`]: false
            });
            return;
        } else {
            this.props.verificationAdded();
            $.ajax({
                type: "POST",
                dataType: "json",
                data: JSON.stringify(licenseData),
                url: `${url}/api/user/${route}`,
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + token
                },
                success: function (response) {
                    console.log(response);
                    that.setState({
                        [`loading_${name}`]: false,
                        [`verification_${name}`]: true
                    });

                    that.successMessage(`Your ${name} license was successfully submitted`)
                },
                error: function (error) {
                    console.log(error);
                    that.setState({
                        [`loading_${name}`]: false
                    });

                    that.errorMessage(`There was a problem uploading your ${name} license. Please try again !`)
                }
            });
        }
    };

    handleIdentificationCard = (event, name, route) => {
        event.preventDefault();
        const that = this;

        let token = localStorage.getItem("token");
        console.log(token);

        this.setState({
            [`loading_${name}`]: true
        });

        let identificationData = this.state[name];

        if (this.state[name].identification_expiration_date === "") {
            that.errorMessage("Please add an expiration date");
            this.setState({
                [`loading_${name}`]: false
            });
            return;
        }

        if (this.state[name][`${name}_image`].length !== 1) {
            that.errorMessage("Please upload an identity card");
            this.setState({
                [`loading_${name}`]: false
            });
            return;
        } else {
            this.props.verificationAdded();
            $.ajax({
                type: "POST",
                dataType: "json",
                url: `${url}/api/user/${route}`,
                data: JSON.stringify(identificationData),
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + token
                },
                success: function (response) {
                    console.log(response);
                    that.setState({
                        [`loading_${name}`]: false,
                        [`verification_${name}`]: true
                    });

                    that.successMessage("Your Identity Card submitted successfully !")
                },
                error: function (error) {
                    console.log(error);
                    that.setState({
                        [`loading_${name}`]: false
                    });

                    that.errorMessage("There was some problem uploading your Identity Card. Please try again !")
                }
            });
        }

    }

    enterLoading = () => {
        this.setState({ loading: true });
    };

    // File Uploader
    uploaderFiles = (files, name) => {
        this.setState(prevState => ({
            [name]: {
                ...prevState[name],
                [`${name}_image`]: files
            }
        }))
    };

    // File Uploader Handler
    onDrop = (picture) => {
        this.setState({
            pictures: picture
        });
    };

    // Show Error Message
    errorMessage = (error) => {
        message.config({
            top: 100,
            duration: 3
        });
        message.error(error);
    };

    // Show Success Message
    successMessage = (success) => {
        message.config({
            top: 100,
            duration: 3
        });
        message.success(success);
    };

    // Business License
    renderBusinessLicense = (user, uploader) => {
        let business = (<Card bordered={true} hoverable={true} loading={false}>
            <div className="validation-form-wrapper">
                <Skeleton active paragraph={{ rows: 20 }} />
            </div>
        </Card>);

        if (!_.isEmpty(user)) {

            if (user.business_license.verified === -10) {
                return business = (
                    <Card bordered={true} hoverable={true} loading={false}>
                        <div className="validation-form-wrapper">
                            <h3>Business Licence</h3>
                            <form onSubmit={(event) => this.handleForm(event, "business", "newBuLicence")}>
                                <div className="form-grp">
                                    <label>Number of the business license <span>(*)</span></label>
                                    <Input required
                                        name="business_license_number"
                                        onChange={(event) => {
                                            event.persist();
                                            this.handleInput(event, "business")
                                        }} />
                                </div>

                                <div className="form-grp">
                                    <label>Expiration Date <span>(*)</span></label>
                                    <DatePicker required
                                        name="business"
                                        onChange={(event) => {
                                            this.handleCalendar(event, "business")
                                        }}
                                    />

                                </div>

                                <div className="form-grp text-center">
                                    <label style={{ textAlign: "left", display: "block" }}>Upload your
                                        licence <span>(*)</span></label>
                                    <Uploader
                                        propOptions={uploader}
                                        files={(files) => this.uploaderFiles(files, "business")}
                                        key={this.state.uploader_reset}
                                        propOptions={{ singleImage: false }}
                                    />
                                </div>

                                <div className="form-grp text-center">
                                    <p className="mandatory">Fields marked as <span>(*)</span> are mandatory</p>
                                    <p className="mandatory"><span>(*) </span>
                                        Please Note: If you do not have an Industry License a fee of
                                        $9.99 per month will be applied to be verified !
                                    </p>
                                    <Button htmlType="submit" type="primary" loading={this.state.loading_business}>
                                        Submit Business License
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </Card>
                )
            }

            switch (user.business_license.verified) {
                case -1:
                    return business = (
                        <Card bordered={true} hoverable={true} loading={false}>
                            <div className="validation-form-wrapper">
                                <h3>Business License</h3>
                                <h4>
                                    <Icon type="close-circle" className="verification-icon"
                                        style={{ color: "indianred" }} />
                                    Verification Status:
                                    <span style={{ color: "indianred", fontWeight: "bold" }}> REFUSED</span>
                                </h4>
                                <h5 style={{ textAlign: "center", fontStyle: "italic", marginBottom: "50px" }}>- Please
                                    Re-Submit your Business License -</h5>
                                <form onSubmit={(event) => this.handleForm(event, "business", "newBuLicence")}
                                    style={this.state.verification_business ? { display: "none" } : { display: "block" }}
                                >
                                    <div className="form-grp">
                                        <label>Number of the business license <span>(*)</span></label>
                                        <Input required
                                            name="business_license_number"
                                            onChange={(event) => {
                                                event.persist();
                                                this.handleInput(event, "business")
                                            }} />
                                    </div>

                                    <div className="form-grp">
                                        <label>Expiration Date <span>(*)</span></label>
                                        <DatePicker required
                                            name="business"
                                            onChange={(event) => {
                                                this.handleCalendar(event, "business")
                                            }}
                                        />

                                    </div>

                                    <div className="form-grp text-center">
                                        <label style={{ textAlign: "left", display: "block" }}>Upload your
                                            licence <span>(*)</span></label>
                                        <Uploader
                                            propOptions={uploader}
                                            files={(files) => this.uploaderFiles(files, "business")}
                                            key={this.state.uploader_reset}
                                            propOptions={{ singleImage: false }}
                                        />
                                    </div>

                                    <div className="form-grp text-center">
                                        <p className="mandatory">Fields marked as <span>(*)</span> are mandatory</p>
                                        <p className="mandatory"><span>(*) </span>
                                            Please Note: If you do not have an Industry License a fee of
                                            $9.99 per month will be applied to be verified !
                                        </p>
                                        <Button htmlType="submit" type="primary" loading={this.state.loading_business}>
                                            Submit Business License
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </Card>
                    )
                case 1:
                    return business = (
                        <Card bordered={true} hoverable={true} loading={false}>
                            <div className="validation-form-wrapper">
                                <h3>Business License</h3>
                                <h4>
                                    <Icon type="close-circle" className="verification-icon" style={{ color: "#00cc00" }} />
                                    Verification Status:
                                    <span style={{ color: "#00cc00", fontWeight: "bold" }}> APPROVED</span>
                                </h4>
                            </div>
                        </Card>
                    )
                case 2:
                    return business = (
                        <Card bordered={true} hoverable={true} loading={false}>
                            <div className="validation-form-wrapper">
                                <h3>Business License</h3>
                                <h4>
                                    <Icon type="check-circle" className="verification-icon"
                                        style={{ color: "darkgray" }} />
                                    Verification Status:
                                    <span style={{ color: "darkgray", fontWeight: "bold" }}> PENDING</span>
                                </h4>
                            </div>
                        </Card>
                    )
                default:
                    return null
            }

        }
        return business;
    }

    renderIndustrialLicense = (user, uploader) => {
        let industrial = (<Card bordered={true} hoverable={true} loading={false}>
            <div className="validation-form-wrapper">
                <Skeleton active paragraph={{ rows: 20 }} />
            </div>
        </Card>);

        if (!_.isEmpty(user)) {

            if (user.industrial_license.verified === -10) {
                return industrial = (
                    <Card bordered={true} hoverable={true} loading={false}>
                        <div className="validation-form-wrapper">
                            <h3>Industrial Licence</h3>
                            <form onSubmit={(event) => this.handleForm(event, "industrial", "newInLicence")}>
                                <div className="form-grp">
                                    <label>Number of the industrial license <span>(*)</span></label>
                                    <Input required
                                        name="industrial_license_number"
                                        onChange={(event) => {
                                            event.persist();
                                            this.handleInput(event, "industrial")
                                        }} />
                                </div>

                                <div className="form-grp">
                                    <label>Expiration Date <span>(*)</span></label>
                                    <DatePicker required
                                        name="industrial"
                                        onChange={(event) => {
                                            this.handleCalendar(event, "industrial")
                                        }}
                                    />

                                </div>

                                <div className="form-grp text-center">
                                    <label style={{ textAlign: "left", display: "block" }}>Upload your
                                        licence <span>(*)</span></label>
                                    <Uploader
                                        propOptions={uploader}
                                        files={(files) => this.uploaderFiles(files, "industrial")}
                                        key={this.state.uploader_reset}
                                        propOptions={{ singleImage: false }}
                                    />
                                </div>

                                <div className="form-grp text-center">
                                    <p className="mandatory">Fields marked as <span>(*)</span> are mandatory</p>
                                    <Button htmlType="submit" type="primary" loading={this.state.loading_industrial}>
                                        Submit Industrial License
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </Card>
                )
            }

            switch (user.industrial_license.verified) {
                case -1:
                    return industrial = (
                        <Card bordered={true} hoverable={true} loading={false}>
                            <div className="validation-form-wrapper">
                                <h3>Industrial License</h3>
                                <h4>
                                    <Icon type="close-circle" className="verification-icon"
                                        style={{ color: "indianred" }} />
                                    Verification Status:
                                    <span style={{ color: "indianred", fontWeight: "bold" }}> REFUSED</span>
                                </h4>
                                <h5 style={{ textAlign: "center", fontStyle: "italic", marginBottom: "50px" }}>- Please
                                    Re-Submit your Industrial License -</h5>
                                <form onSubmit={(event) => this.handleForm(event, "industrial", "newInLicence")}
                                    style={this.state.verification_industrial ? { display: "none" } : { display: "block" }}
                                >
                                    <div className="form-grp">
                                        <label>Number of the industrial license <span>(*)</span></label>
                                        <Input required
                                            name="industrial_license_number"
                                            onChange={(event) => {
                                                event.persist();
                                                this.handleInput(event, "industrial")
                                            }} />
                                    </div>

                                    <div className="form-grp">
                                        <label>Expiration Date <span>(*)</span></label>
                                        <DatePicker required
                                            name="industrial"
                                            onChange={(event) => {
                                                this.handleCalendar(event, "industrial")
                                            }}
                                        />

                                    </div>

                                    <div className="form-grp text-center">
                                        <label style={{ textAlign: "left", display: "block" }}>Upload your
                                            licence <span>(*)</span></label>
                                        <Uploader
                                            propOptions={uploader}
                                            files={(files) => this.uploaderFiles(files, "industrial")}
                                            key={this.state.uploader_reset}
                                            propOptions={{ singleImage: false }}
                                        />
                                    </div>

                                    <div className="form-grp text-center">
                                        <p className="mandatory">Fields marked as <span>(*)</span> are mandatory</p>
                                        <Button htmlType="submit" type="primary"
                                            loading={this.state.loading_industrial}>
                                            Submit Industrial License
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </Card>
                    )
                case 1:
                    return industrial = (
                        <Card bordered={true} hoverable={true} loading={false}>
                            <div className="validation-form-wrapper">
                                <h3>Industrial License</h3>
                                <h4>
                                    <Icon type="close-circle" className="verification-icon" style={{ color: "#00cc00" }} />
                                    Verification Status:
                                    <span style={{ color: "#00cc00", fontWeight: "bold" }}> APPROVED</span>
                                </h4>
                            </div>
                        </Card>
                    )
                case 2:
                    return industrial = (
                        <Card bordered={true} hoverable={true} loading={false}>
                            <div className="validation-form-wrapper">
                                <h3>Industrial License</h3>
                                <h4>
                                    <Icon type="check-circle" className="verification-icon"
                                        style={{ color: "darkgray" }} />
                                    Verification Status:
                                    <span style={{ color: "darkgray", fontWeight: "bold" }}> PENDING</span>
                                </h4>
                            </div>
                        </Card>
                    )
                default:
                    return null
            }

        }
        return industrial;
    }

    renderIdentityCard = (user, uploader) => {

        let identityCard = (<Card bordered={true} hoverable={true} loading={false}>
            <div className="validation-form-wrapper">
                <Skeleton active paragraph={{ rows: 20 }} />
            </div>
        </Card>);

        if (!_.isEmpty(user)) {
            if (user.picture_i_d.verified === -10) {

                return identityCard = <Card bordered={true} hoverable={true} loading={false}>
                    <div className="validation-form-wrapper">
                        <h3>Identity Card</h3>
                        <form
                            onSubmit={(event) => this.handleIdentificationCard(event, "identification", "verificationDataId")}>
                            <div className="form-grp">
                                <label>Enter card expiration date <span>(*)</span></label>
                                <DatePicker required
                                    name="identification"
                                    onChange={(event) => {
                                        this.handleCalendar(event, "identification")
                                    }}
                                />
                            </div>

                            <div className="form-grp">
                                <Uploader
                                    propOptions={uploader}
                                    files={(files) => this.uploaderFiles(files, "identification")}
                                    key={this.state.uploader_reset}
                                />
                            </div>
                            <div className="form-grp text-center">
                                <Button htmlType="submit" type="primary"
                                    loading={this.state.loading_identification}>
                                    Submit Identity Card
                                </Button>
                            </div>
                        </form>
                    </div>
                </Card>
            }

            switch (user.picture_i_d.verified) {
                case -1:
                    return identityCard = (
                        <Card bordered={true} hoverable={true} loading={false}>
                            <div className="validation-form-wrapper">
                                <h3>Identity Card</h3>
                                <h4>
                                    <Icon type="close-circle" className="verification-icon"
                                        style={{ color: "indianred" }} />
                                    Verification Status:
                                    <span style={{ color: "indianred", fontWeight: "bold" }}> REFUSED</span>
                                </h4>
                                <h5 style={{ textAlign: "center", fontStyle: "italic", marginBottom: "50px" }}>- Please
                                    Re-Submit your Identity Card -</h5>
                                <form
                                    onSubmit={(event) => this.handleIdentificationCard(event, "identification", "verificationDataId")}
                                    style={this.state.verification_identification ? { display: "none" } : { display: "block" }}
                                >
                                    <div className="form-grp">
                                        <label>Enter card expiration date <span>(*)</span></label>
                                        <Input required type="text"
                                            value={this.state.identification.identification_expiration_date}
                                            name="identification_expiration_date" onChange={(event) => {
                                                event.persist();
                                                this.handleInput(event, "identification")
                                            }} />
                                    </div>

                                    <div className="form-grp">
                                        <Uploader
                                            propOptions={uploader}
                                            files={(files) => this.uploaderFiles(files, "identification")}
                                            key={this.state.uploader_reset}
                                        />
                                    </div>
                                    <div className="form-grp text-center">
                                        <Button htmlType="submit" type="primary"
                                            loading={this.state.loading_identification}>
                                            Submit Identity Card
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </Card>
                    )
                case 1:
                    return identityCard = (
                        <Card bordered={true} hoverable={true} loading={false}>
                            <div className="validation-form-wrapper">
                                <h3>Identity Card</h3>
                                <h4>
                                    <Icon type="close-circle" className="verification-icon" style={{ color: "#00cc00" }} />
                                    Verification Status:
                                    <span style={{ color: "#00cc00", fontWeight: "bold" }}> APPROVED</span>
                                </h4>
                            </div>
                        </Card>
                    )
                case 2:
                    return identityCard = (
                        <Card bordered={true} hoverable={true} loading={false}>
                            <div className="validation-form-wrapper">
                                <h3>Identity Card</h3>
                                <h4>
                                    <Icon type="check-circle" className="verification-icon"
                                        style={{ color: "darkgray" }} />
                                    Verification Status:
                                    <span style={{ color: "darkgray", fontWeight: "bold" }}> PENDING</span>
                                </h4>
                            </div>
                        </Card>
                    )
                default:
                    return null
            }
        }

        return identityCard
    }

    handleAdditionalForm = (event, name, route) => {
        event.preventDefault();
        const token = localStorage.getItem("token");
        const that = this;

        this.setState({
            [`loading_${name}`]: true
        });

        const images = {
            additional_documents: that.state.additional.additional_image
        }

        console.log(images);

        if (this.state.additional.additional_image.length < 1 || this.state.additional.additional_image.length > 5) {
            this.setState({
                [`loading_${name}`]: false
            });

            this.errorMessage("The amount of documents shouldn't be less than 1 or more than 5")
            return
        } else {
            $.ajax({
                type: "POST",
                dataType: "json",
                url: `${url}/api/user/supportingdocuments`,
                data: JSON.stringify(images),
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + token
                },
                success: function (response) {
                    console.log(response);
                    that.successMessage("Additional Supporting Documents have been submitted !")
                    that.setState({
                        [`loading_${name}`]: false,
                        uploader_reset: true
                    });
                    window.scrollTo(0, 0)
                },
                error: function (error) {
                    console.log(error);
                    that.setState({
                        [`loading_${name}`]: false
                    });
                }
            });
        }
    }

    renderAdditionalDocuments = (user, uploader) => {
        let additionalDocuments = (<Card bordered={true} hoverable={true} loading={false}>
            <div className="validation-form-wrapper">
                <Skeleton active paragraph={{ rows: 20 }} />
            </div>
        </Card>);

        if (!_.isEmpty(user)) {
            additionalDocuments = <Card bordered={true} hoverable={true} loading={false}>
                <div className="validation-form-wrapper">
                    <h3>Additional Supporting Documents</h3>
                    <h4 style={{ marginTop: "-50px", marginBottom: "60px", textAlign: "center", fontSize: "16px" }}>
                        <span style={{ color: "indianred" }}>(!) </span> Please note: If you are not the business license
                        holder,
                        a signed letter by the business owner allowing you to represent the company will be needed
                    </h4>
                    <form onSubmit={(event) => this.handleAdditionalForm(event, "additional", "additionalDocuments")}
                        style={this.state.verification_additional ? { display: "none" } : { display: "block" }}
                    >
                        <div className="form-grp">
                            <Uploader
                                propOptions={uploader}
                                files={(files) => this.uploaderFiles(files, "additional")}
                                key={this.state.uploader_reset}
                            />
                        </div>
                        <div className="form-grp text-center">
                            <Button htmlType="submit" type="primary"
                                loading={this.state.loading_additional}>
                                Submit Additional Documents
                            </Button>
                        </div>
                    </form>
                </div>
            </Card>
        }

        return additionalDocuments;
    }

    render() {

        const uploaderOptions = {
            buttonText: "Upload your license",
        }

        const user = this.props.userData;

        const additionalUploaderOptions = {
            buttonText: "Choose documents",
            singleImage: false
        }

        if (!this.props.authenticated) {
            return <Redirect to="/signin" />
        }

        // if(!user.verification_step_1) {
        //     return <Redirect to="/" />
        // }

        return (
            <div className="verification-wrapper">
                <h1 style={{ textAlign: "center", marginBottom: "50px" }}>Licenses (Step 2)</h1>

                <div className="verification-box-wrapper">
                    <div className="verification-box-wrapper__item">
                        {this.renderIdentityCard(user, uploaderOptions)}
                    </div>
                    <div className="verification-box-wrapper__item">
                        {this.renderIndustrialLicense(user, uploaderOptions)}
                    </div>
                    <div className="verification-box-wrapper__item">
                        {this.renderBusinessLicense(user, uploaderOptions)}
                    </div>
                    <div className="verification-box-wrapper__item">
                        {this.renderAdditionalDocuments(user, additionalUploaderOptions)}
                    </div>
                </div>

                <div className="text-center payment-link">
                    <Link to="/verification-payment">Payment (Step 3)</Link>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authenticated: state.auth.authenticated,
        userData: state.auth.userData,
        licenseAdded: state.user.verificationAdded
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        userInfo: (info) => dispatch(userInfo(info)),
        verificationAdded: () => dispatch(verificationAdded()),
        verificationRemoved: () => dispatch(verificationRemoved())
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Verification);
// Here we are importing all the reducers

import authReducer from "./authReducer";
import userReducer from "./userReducer";
import productsReducer from "./productsReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    products: productsReducer
});

export default rootReducer;

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// Check if there is Token Available

// Apply middleware is used to apply thunk as a parameter
import { createStore, applyMiddleware } from "redux";
import rootReducer from "./store/reducers/rootReducer";

// Binder - this connects Redux with the Application
import { Provider } from "react-redux";

// Thunk is a middleware used for async calls
import thunk from "redux-thunk";

// Application's Store & Root
const store = createStore(rootReducer, applyMiddleware(thunk));
const rootElement = document.getElementById('root');

// Check if there is a TOKEN and update the Store
const token = localStorage.getItem("token") ? true : false;

store.dispatch({
  type: "AUTHENTICATED",
  payload: token
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootElement
);

serviceWorker.unregister();

import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter, Link } from "react-router-dom";
import { userInfo, authToken } from "../../store/actions/authActions";
import { setProducts } from "../../store/actions/productActions";
import { Input, Select, Button, Checkbox, Skeleton, Card, Icon, Modal, message } from 'antd';
import ImageUploader from 'react-images-upload';
import _ from "lodash";
import Cookies from "js-cookie";
import Countries from "./ProductCountries";
import Categories from "./ProductCategories";
import { url } from "../../config";
import "./ProductPost.scss";
import $ from "jquery";

const { Option } = Select;
const { TextArea } = Input;


class ProductPost extends Component {

    state = {
        product_title: "",
        product_description: "",
        product_price: "",
        product_country: "",
        product_type: "",
        product_category: "",
        product_phone: "",
        pictures: "",
        random: "",
        loading: false,
        productPosted: false,
        toggle_phone_number: true,
        toggle_phone_checkboxes: false,
        product_category_name: "",
        phone_calls: false,
        text_sms: false,
        modalShow: false,
        show_pictures: []
    };

    componentDidMount() {
        const token = localStorage.getItem("token");
        const user_id = localStorage.getItem("uid");

        var that = this;

        if (token !== null) {
            $.ajax({
                type: "GET",
                dataType: "json",
                url: `${url}/api/users/list?token=${token}`,
                success: function (response) {
                    const userData = response.data;
                    that.props.userInfo(userData);
                },
                error: function (error) {
                    localStorage.removeItem("token");
                    that.props.authToken();
                }
            });

            $.ajax({
                type: "POST",
                url: `${url}/api/user/products`,
                dataType: "json",
                data: JSON.stringify({ user_id: user_id }),
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + token
                },
                success: function (response) {
                    const products = response.products;
                    that.props.setProducts(products);
                },
                error: function (error) {
                    console.log(error);
                }
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const token = localStorage.getItem("token");
        const user_id = localStorage.getItem("uid");

        const that = this;

        if (prevState.productPosted !== this.state.productPosted) {
            this.setState({
                productPosted: false
            });
            $.ajax({
                type: "POST",
                url: `${url}/api/user/products`,
                dataType: "json",
                data: JSON.stringify({ user_id: user_id }),
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + token
                },
                success: function (response) {
                    const products = response.products;
                    that.props.setProducts(products);
                },
                error: function (error) { console.log(error); }
            });
        }
    }

    handleInput = (event) => {
        this.saveToDraft();
        this.setState({
            [event.target.name]: event.target.value
        })
    };

    handleSelectChange = (value, name) => {
        this.saveToDraft();
        this.setState({
            [name]: value
        })
    };

    saveToDraft = () => {
        var draft = this.state;
        var inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000);
        Cookies.set("_draft", draft, { expires: inFifteenMinutes });
    }

    toggleModal = () => {
        this.setState({
            modalShow: !this.state.modalShow
        })
    }

    cancelModal = () => {
        this.setState({
            modalShow: false
        })
    }

    clickToSubmit = () => {
        this.handleForm();
    }

    errorMessage = (error) => {
        message.config({
            top: 100,
            duration: 3
        });
        message.error(error);
    };


    handleForm = () => {
        const token = localStorage.getItem("token");

        const that = this;

        this.setState({
            loading: true
        })

        const productInfo = {
            title: this.state.product_title,
            description: this.state.product_description,
            price: this.state.product_price,
            country: this.state.product_country,
            type: this.state.product_type,
            category: this.state.product_category,
            image: this.state.pictures,
            phone: this.state.product_phone,
            phone_calls: this.state.phone_calls,
            text_sms: this.state.text_sms
        };

        if (_.includes(this.state.product_phone, /(?:[^\-]*)/)) {
            that.errorMessage("Phone number should contain only digits");
            this.setState({
                loading: false
            })
            return;
        }

        if (this.state.toggle_phone_checkboxes) {
            if (this.state.product_phone.length < 9) {
                that.errorMessage("Phone number should contain 9 digits");
                this.setState({
                    loading: false
                })
                return;
            }
        }

        $.ajax({
            type: "POST",
            url: `${url}/api/user/newproduct`,
            dataType: "json",
            data: JSON.stringify(productInfo),
            headers: {
                'Content-Type': 'application/json',
                'authorization': 'Bearer ' + token
            },
            success: function (response) {
                console.log(response.id);

                if (response.status === "success") {
                    setTimeout(() => {
                        that.successMessage("Posting uploaded successfully !")
                    }, 1000)

                    that.setState({
                        modalShow: false
                    });
                    setTimeout(() => {
                        that.props.history.push(`/postings/${response.id}`)
                    }, 2500)
                }

                if (response.status === "failed") {
                    that.errorMessage("You have reached your monthly posting limit. Upload appropriate licenses or add subscription !");
                    that.setState({
                        loading: false
                    })
                    return;
                }

                that.setState({
                    random: Math.random(),
                    product_title: "",
                    product_description: "",
                    product_price: "",
                    product_country: "",
                    product_type: "",
                    product_category: "",
                    pictures: "",
                    product_phone: "",
                    loading: false,
                    productPosted: true,
                    phone_calls: false,
                    phone_calls: false,
                    text_sms: false,
                    toggle_phone_checkboxes: false
                });
            },
            error: function (error) {
                console.log(error);
                that.errorMessage("All fields are required !");
                that.setState({
                    loading: false
                })
            }
        });

    };

    onImageDrop = (pictureFiles, pictureDataURLs) => {
        this.setState({
            pictures: pictureDataURLs
        });
    };

    successMessage = (success) => {
        message.config({
            top: 100,
            duration: 3
        });
        message.success(success);
    };

    errorMessage = (error) => {
        message.config({
            top: 100,
            duration: 3
        });
        message.error(error);
    };

    toggleInputPhone = () => {
        this.setState({
            toggle_phone_number: !this.state.toggle_phone_number,
            toggle_phone_checkboxes: !this.state.toggle_phone_checkboxes,
            phone_calls: false,
            text_sms: false
        })
    }

    checkboxHandler = (event) => {
        this.setState({
            [event.target.name]: event.target.checked
        })
    }

    onKeyUpHandler = (event) => {
        if (event.keyCode === 13) {
            this.handleForm();
        }
    }


    render() {

        console.log(this.state);

        const label = {
            textAlign: "left",
            display: "block"
        };

        if (!this.props.authenticated) {
            return <Redirect to="/signin" />
        }


        var cookies = Cookies.get("_draft");
        if(cookies === null || cookies === "" || cookies === undefined) {
            cookies = [];
        } else {
            cookies = JSON.parse(Cookies.get("_draft"));
        }
      
        let productPost = <Skeleton active />;
        if (this.props.userData.canPost === 1) {
            productPost = (
                <div className="post-product-form">
                    <form className="product-post-form-wrapper">
                        <div className="form-grp">
                            <label>Posting title <span>(*)</span></label>
                            <Input required name="product_title"
                                onChange={(event) => this.handleInput(event)}
                                onKeyUp={(event) => this.onKeyUpHandler(event)}
                                placeholder={cookies.product_title}
                                required
                            />
                        </div>
                        <div className="form-grp">
                            <label>Posting type <span>(*)</span></label>
                            <Select required
                                onChange={(event) => this.handleSelectChange(event, "product_type")}
                                name="product_type"
                                placeholder={_.upperFirst(cookies.product_type)}
                                allowClear={true}
                            >
                                <Option value="hemp">Hemp</Option>
                                <Option value="cannabis">Cannabis</Option>
                            </Select>
                        </div>
                        <div className="form-grp">
                            <label>Posting category <span>(*)</span></label>
                            <Categories categories={(value, name) => this.handleSelectChange(value, name)}
                                placeholder={cookies.product_category}
                            />
                        </div>
                        <div className="form-grp product-price-input">
                            <label>Posting price <span>(*)</span></label>
                            <b>$</b>
                            <input type="number"
                                name="product_price"
                                placeholder={cookies.product_price}
                                onChange={(event) => this.handleInput(event)}
                                onKeyUp={(event) => this.onKeyUpHandler(event)}
                            />
                        </div>
                        <div className="form-grp">
                            <label>State <span>(*)</span></label>
                            <Countries countries={(value, name) => this.handleSelectChange(value, name)}
                                placeholder={cookies.product_country}
                            />
                        </div>

                        <div className="form-grp product-price-input">
                            <label>Phone: <span>(Should contain digits. Optional)</span></label>
                            <b>☏</b>
                            <input type="number"
                                name="product_phone"
                                placeholder={cookies.product_phone}
                                onChange={(event) => this.handleInput(event)}
                                onKeyUp={(event) => this.onKeyUpHandler(event)}
                                disabled={this.state.toggle_phone_number}
                            />
                            <div className="checkbox-phone-group">
                                <Checkbox onChange={this.toggleInputPhone} checked={this.state.toggle_phone_checkboxes} name="show_number">Show my phone number</Checkbox>
                                <Checkbox
                                    style={!this.state.toggle_phone_checkboxes ? { display: "none" } : { display: "inline-block" }}
                                    onChange={(event) => this.checkboxHandler(event)}
                                    name="phone_calls"
                                    checked={this.state.phone_calls}
                                >Phone calls</Checkbox>
                                <Checkbox
                                    style={!this.state.toggle_phone_checkboxes ? { display: "none" } : { display: "inline-block" }}
                                    onChange={(event) => this.checkboxHandler(event)}
                                    name="text_sms"
                                    checked={this.state.text_sms}
                                >Text / SMS</Checkbox>
                            </div>
                        </div>

                        <div className="form-grp text-center" data-input="description">
                            <label style={label}>Posting description <span>(*)</span></label>
                            <TextArea rows={8}
                                onChange={(event) => this.handleInput(event)}
                                name="product_description"
                                onKeyUp={(event) => this.onKeyUpHandler(event)}
                                placeholder={cookies.product_description}
                            />
                        </div>

                        <div className="form-grp text-center" data-input="uploader">
                            <label style={label}>Upload posting images</label>
                            <ImageUploader
                                withIcon={true}
                                buttonText='Choose images'
                                onChange={this.onImageDrop}
                                imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                                maxFileSize={5242880}
                                withPreview={true}
                                minImages={1}
                                maxImages={10}
                                label={"Max file size: 10mb, accepted: jpeg|jpg|png|gif"}
                                key={this.state.random}
                            />
                        </div>

                        <div className="form-grp text-center">
                            <p className="mandatory">Fields marked as <span>(*)</span> are mandatory</p>
                            <Button type="primary" onClick={this.toggleModal}>Preview Posting</Button>
                            {/* <Button type="primary" onClick={this.saveToDraft}>Save Posting</Button> */}
                        </div>
                    </form>
                </div>
            )
        }
        else if (this.props.userData.canPost === 0) {
            productPost = <div className="monthly-limit text-center">
                <Card bordered={true} style={{ width: 300 }}>
                    <h3><Icon type="close-circle" style={{
                        color: "indianred",
                        fontSize: "40px",
                        fontWeight: "bold",
                        marginBottom: "15px"
                    }} /><br />Monthly limit exceeded. Activate subscription or submit all licenses to submit more.</h3>
                    <Link to="/verification" className="ant-btn ant-btn-primary">Verify your account</Link>
                </Card>
            </div>
        }

        return (
            <div className="product-post-wrapper">
                <h1 style={{ textAlign: "center", marginBottom: "50px" }}>List a Posting</h1>
                <Modal
                    title="Preview Listing"
                    visible={this.state.modalShow}
                    onOk={this.clickToSubmit}
                    okText="Submit Posting"
                    cancelText="Close"
                    onCancel={this.cancelModal}
                >
                    <div className="product-post-wrapper__overview">
                        <h2><b>Posting title: </b> {this.state.product_title !== "" ? this.state.product_title : "Submit posting title"}</h2>
                        <ul>
                            <li><b>Posting type: </b> {this.state.product_type !== "" ? this.state.product_type : "/"}</li>
                            <li><b>Posting category: </b> {this.state.product_category !== "" ? this.state.product_category : "/"}</li>
                            <li><b>State: </b> {this.state.product_country !== "" ? this.state.product_country : "/"}</li>
                            <li><b>Posting price: </b> {this.state.product_price !== "" ? this.state.product_price : "/"} <span>$</span></li>
                            <li><b>Phone: </b> {this.state.product_phone !== "" ? this.state.product_phone : "/"} <span>☏</span></li>
                            <li><b>Posting Description: </b> {this.state.product_description !== "" ? this.state.product_description : "/"}</li>
                        </ul>

                        <h4>{!_.isEmpty(this.state.pictures) ? <b>Posting images:</b> : "No listing images posted"}</h4>
                        <div>
                            {_.map(this.state.pictures, (item, index) => {
                                return (
                                    <span key={index}>
                                        <img src={item} />
                                    </span>
                                )
                            })}
                        </div>

                    </div>
                </Modal>
                {productPost}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    console.log(state);
    return {
        authenticated: state.auth.authenticated,
        userData: state.auth.userData
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        userInfo: (info) => dispatch(userInfo(info)),
        authToken: () => dispatch(authToken()),
        setProducts: (products) => dispatch(setProducts(products))
    }
};

ProductPost = withRouter(ProductPost);
export default connect(mapStateToProps, mapDispatchToProps)(ProductPost);
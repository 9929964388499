import React, { Component } from "react";
import { Select } from "antd";

const { Option, OptGroup } = Select;

class Countries extends Component {

    state = {
        product_country: "",
    }

    handleSelectChange = (value, name) => {
        this.setState({
            [name]: value
        })
        this.props.countries(value, name);
    };

    render() {
        return (
            <>
                <Select required onChange={(event) => this.handleSelectChange(event, "product_country")}
                    name="product_country" allowClear={true} defaultValue={this.props.country !== null ? this.props.country : ""}>
                    <OptGroup label="All recreational cannabis states">
                        <Option value="alaska">Alaska</Option>
                        <Option value="california">California</Option>
                        <Option value="colorado">Colorado</Option>
                        <Option value="maine">Maine</Option>
                        <Option value="michigan">Michigan</Option>
                        <Option value="nevada">Nevada</Option>
                        <Option value="oregon">Oregon</Option>
                        <Option value="vermont">Vermont</Option>
                        <Option value="washington">Washington</Option>
                    </OptGroup>
                    <OptGroup label="All medical / recreational cannabis states">
                        <Option value="alabama">Alabama</Option>
                        <Option value="arkansas">Arkansas</Option>
                        <Option value="american_samoa">American Samoa</Option>
                        <Option value="arizona">Arizona</Option>
                        <Option value="connecticut">Connecticut</Option>
                        <Option value="columbia_district">District of Columbia</Option>
                        <Option value="delaware">Delaware</Option>
                        <Option value="florida">Florida</Option>
                        <Option value="georgia">Georgia</Option>
                        <Option value="guam">Guam</Option>
                        <Option value="hawaii">Hawaii</Option>
                        <Option value="iowa">Iowa</Option>
                        <Option value="idaho">Idaho</Option>
                        <Option value="illinois">Illinois</Option>
                        <Option value="indiana">Indiana</Option>
                        <Option value="kansas">Kansas</Option>
                        <Option value="kentucky">Kentucky</Option>
                        <Option value="louisiana">Louisiana</Option>
                        <Option value="massachusetts">Massachusetts</Option>
                        <Option value="maryland">Maryland</Option>
                        <Option value="minnesota">Minnesota</Option>
                        <Option value="missouri">Missouri</Option>
                        <Option value="mississippi">Mississippi</Option>
                        <Option value="montana">Montana</Option>
                        <Option value="north_carolina">North Carolina</Option>
                        <Option value="north_dakota">North Dakota</Option>
                        <Option value="nebraska">Nebraska</Option>
                        <Option value="new_hampshire">New Hampshire</Option>
                        <Option value="new_jersey">New Jersey</Option>
                        <Option value="new_mexico">New Mexico</Option>
                        <Option value="new_york">New York</Option>
                        <Option value="ohio">Ohio</Option>
                        <Option value="oklahoma">Oklahoma</Option>
                        <Option value="pennsylvania">Pennsylvania</Option>
                        <Option value="puerto_rico">Puerto Rico</Option>
                        <Option value="rhode_island">Rhode Island</Option>
                        <Option value="south_carolina">South Carolina</Option>
                        <Option value="south_dakota">South Dakota</Option>
                        <Option value="tennessee">Tennessee</Option>
                        <Option value="texas">Texas</Option>
                        <Option value="utah">Utah</Option>
                        <Option value="virginia">Virginia</Option>
                        <Option value="virgin_islands">Virgin Islands</Option>
                        <Option value="wisconsin">Wisconsin</Option>
                        <Option value="west_virginia">West Virginia</Option>
                        <Option value="wyoming">Wyoming</Option>
                    </OptGroup>
                </Select>
            </>
        )
    }
}

export default Countries;
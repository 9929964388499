import React, { Component } from "react";
import { connect } from "react-redux";
import { setProducts } from "../../../store/actions/productActions";
import { withRouter } from "react-router-dom";
import { Table, Button, Icon, message } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import $ from "jquery";
import { url } from "../../../config";
import "./Table.scss";

class TableComponent extends Component {

  state = {
    tableLoading: true
  };

  onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  loadNewProducts = () => {
    const token = localStorage.getItem("token");
    const user_id = localStorage.getItem("uid");
    const that = this;

    $.ajax({
      type: "POST",
      url: `${url}/api/user/products`,
      dataType: "json",
      data: JSON.stringify({ user_id: user_id }),
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + token
      },
      success: function (response) {
        const products = response.products;
        that.props.setProducts(products);
      },
      error: function (error) {
        console.log(error);
      }
    });
  }

  render() {

    const columns = [
      {
        title: '',
        dataIndex: 'view',
        // specify the condition of filtering result
        // here is that finding the name started with `value`,
        // sorter: (a, b) => console.log(b),
        // sortDirections: ['descend'],
      },
      {
        title: 'ID',
        dataIndex: 'post_id',
        // defaultSortOrder: 'descend',
        // sorter: (a, b) => a.age - b.age,
      },
      {
        title: 'Type',
        dataIndex: 'type',
        // defaultSortOrder: 'descend',
        // sorter: (a, b) => a.age - b.age,
      },
      {
        title: 'Title',
        dataIndex: 'post_title',
        // sorter: (a, b) => a.name.length - b.name.length,
        // sorter: (a, b) => a.post_title.length - b.post_title.length,
        // sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Category',
        dataIndex: 'category',
        onFilter: (value, record) => record.address.indexOf(value) === 0,
        // sorter: (a, b) => a.address.length - b.address.length,
        // sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'State',
        dataIndex: 'state',
        onFilter: (value, record) => record.address.indexOf(value) === 0,
        // sorter: (a, b) => a.address.length - b.address.length,
        // sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Date',
        dataIndex: 'date_posted',
        onFilter: (value, record) => record.address.indexOf(value) === 0,
        // sorter: (a, b) => a.address.length - b.address.length,
        // sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Phone',
        dataIndex: 'phone',
        onFilter: (value, record) => record.address.indexOf(value) === 0,
        // sorter: (a, b) => a.address.length - b.address.length,
        // sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Price',
        dataIndex: 'price',
        onFilter: (value, record) => record.address.indexOf(value) === 0,
        // sorter: (a, b) => a.address.length - b.address.length,
        // sortDirections: ['descend', 'ascend'],
      },
    ];

    const successMessage = (success) => {
      message.config({
        top: 100,
        duration: 3
      });
      message.success(success);
    };

    const removeFromFavorites = (productid) => {
      const token = localStorage.getItem("token");
      const that = this;
      const id = {
        id: productid
      }

      $.ajax({
        type: "POST",
        dataType: "json",
        url: `${url}/api/user/removefromsaved`,
        data: JSON.stringify(id),
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + token
        },
        success: function (response) {
          successMessage("Listing removed from saved search !");
          that.loadNewProducts();

        }, error: function (err) {
          console.log(err);
        }
      });
    }

    const activeButtonStyles = {
      display: "block",
      background: "#1c6d5a",
      border: "none",
      padding: "5px",
      color: "white",
      borderRadius: "3px",
      cursor: "pointer"
    }

    let data = [];

    console.log(this.props);

    if (this.props.products !== undefined) {
      if (this.props.products.length > 0) {

        _.forEach(this.props.products, (product, index) => {
          let link_type = "";
          switch (this.props.type) {
            case "View":
              link_type = <Link to={`/postings/${product.id}`}>{this.props.type}</Link>;
              break;
            case "Re-activate":
              link_type = <Button style={activeButtonStyles} onClick={() => this.props.makeActive(product.id)}>{this.props.type}</Button>;
              break;
            case "Remove":
              link_type = (
                <>
                  <a href={`${url}/view/${product.id}`} style={{ textAlign: "center", display: "block" }}>View</a>
                  <Button style={{ textAlign: "center", marginTop: "10px" }} onClick={() => removeFromFavorites(product.id)}><Icon type="delete" style={{ color: "indianred" }} /></Button>
                </>
              )
                ;
              break;
            default:
              return link_type;
          }

          const object_data = {
            key: product.id,
            view: link_type,
            post_id: product.id,
            type: product.type,
            post_title: product.title,
            category: product.subcategory_id,
            state: product.location,
            date_posted: moment(product.created_at).format("MMM-DD-YYYY"),
            phone: product.phone,
            price: "$ " + product.price
          }
          data.push(object_data)
        })
      }
    }

    return (

      <>
        <Table
          columns={columns}
          dataSource={data}
          onChange={this.onChange}
          loading={this.props.products !== undefined ? false : true}
          className="listings-table"
          key={this.state.key}
        >
        </Table>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setProducts: (product) => dispatch(setProducts(product))
  }
}

export default connect(null, mapDispatchToProps)(TableComponent);
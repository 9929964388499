import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { userInfo, authToken } from "./store/actions/authActions";
import { url } from "./config";
import $ from "jquery";

import Loader from "./components/widgets/loader/Loader";
import Dashboard from "./components/dashboard/Dashboard";
import ProductPost from "./components/product/ProductPost";
import ProductDetails from "./components/product/ProductDetails";
import Verification from "./components/verification/Verification";
import StepOne from "./components/verification/verificationStepOne";
import VerificationPayment from "./components/verification/VerificationPayment";
import Wrapper from "./components/wrapper/Wrapper";
import SignUp from "./components/authentication/SignUp";
import SignIn from "./components/authentication/SignIn";
import ForgotPassword from "./components/authentication/ForgotPassword";
import UserSettings from "./components/usersettings/UserSettings";
import Footer from "./components/footer/Footer";

class App extends Component {

  componentDidMount() {
    const token = localStorage.getItem("token");
    var that = this;

    if (token !== null) {
      $.ajax({
        type: "GET",
        dataType: "json",
        url: `${url}/api/users/list?token=${token}`,
        success: function (response) {
          const userData = response.data;
          that.props.userInfo(userData);
        },
        error: function (error) {
          localStorage.removeItem("token");
          that.props.authToken();
        }
      });
    }
  }

  render() {
    let userData = [];
    if (this.props.userData) {
      userData = this.props.userData
    }

    const token = this.props.isAuthenticated;
    let conditionalWrapper = null;
    if (token) {
      conditionalWrapper = (
        <>
          <Wrapper>
            <Loader toggle={this.props.globalLoader} />
            <Switch>
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/signin" component={SignIn} />
              <Route exact path="/signup" component={SignUp} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/post" component={ProductPost} />
              <Route exact path="/postings/:id" component={ProductDetails} />
              <Route exact path="/verification" component={Verification} />
              <Route exact path="/verification-step" component={StepOne} />
              <Route exact path="/verification-payment" component={VerificationPayment} />
              <Route exact path="/settings" component={UserSettings} />
              <Redirect to="/" />
            </Switch>
            <Footer />
          </Wrapper>
        </>
      )
    } else {
      conditionalWrapper = (
        <>
          <Loader toggle={this.props.globalLoader} />
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/signin" component={SignIn} />
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/post" component={ProductPost} />
            <Route exact path="/postings/:id" component={ProductDetails} />
            <Route exact path="/verification" component={Verification} />
            <Route exact path="/verification-step" component={StepOne} />
            <Route exact path="/verification-payment" component={VerificationPayment} />
            <Route exact path="/settings" component={UserSettings} />
            <Redirect to="/" />
          </Switch>
        </>
      )
    }
    return (
      <BrowserRouter>
        {conditionalWrapper}
      </BrowserRouter>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.authenticated,
    userData: state.auth.userData,
    globalLoader: state.auth.globalLoader
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    userInfo: (info) => dispatch(userInfo(info)),
    authToken: () => dispatch(authToken())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
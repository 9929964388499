const initialState = {
    userChanged: false,
    verificationAdded: false,
    verificationStepOne: false
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case "USER_CHANGES":
            return {
                ...state,
                userChanged: action.payload
            };
        case "ADD_VERIFICATION":
            return {
                ...state,
                verificationAdded: true
            }
        case "REMOVE_VERIFICATION":
            return {
                ...state,
                verificationAdded: false
            }
        case "VERIFICATION_STEP_ONE":
            return {
                ...state,
                verificationStepOne: true
            }
        default:
            return state;
    }
};

export default userReducer;


import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/main-logo.svg";
import { url } from "../../config";
import "./MobileNavItems.scss";
import _ from "lodash";

const MobileNavItems = (props) => {

    let membership = null;
    if (!_.isEmpty(props.userData)) {
        if (!props.userData.verification_step_1 !== undefined && props.userData.verification_step_1) {
            membership = (
                <Link to="/verification">Membership</Link>
            )
        }
    }

    return (
        <>
            <div className="mobile-nav-items-wrapper">
                <img src={logo} alt="Jane Verde Logo" />
                <Link to="/">Home</Link>
                <Link to="/post">Create a Listing</Link>
                {membership}
                <hr />
                <a href={`${url}/`} target="_blank">HELP / FAQ</a>
                <a href={`${url}/privacy-policy`} target="_blank">Privacy Policy</a>
                <a href={`${url}/privacy-policy`} target="_blank">Terms of Use</a>
                <a href={`${url}/`} target="_blank">Avoid Scams & Fraud</a>
                <hr />
                <Link to="/settings">Change Password</Link>
                <a href=" #" onClick={props.logOut}>Log Out</a>
            </div>
        </>
    )
}

export default MobileNavItems;
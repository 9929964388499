import React from "react";
import copyright from "../../images/copyright_white.svg";
import "./Footer.scss";

const Footer = () => {
    return (
        <>
            <footer>
                <div>
                    <h4><img src={copyright} alt="Jane Verde Footer SVG Icon" /> JANEVERDE</h4>
                </div>
                <div>
                    <ul>
                        <li><a href="#">Help / Faq</a></li>
                        <li><a href="#">Privacy Policy</a></li>
                        <li><a href="#">Avoid Scams & Fraud</a></li>
                        <li><a href="#">Become verified member</a></li>
                        {/* <li><a href="#"className="last-footer-item">Espanol - ES</a></li> */}
                    </ul>
                    <div className="clearfix"></div>
                </div>
            </footer>
        </>
    )
}

export default Footer;
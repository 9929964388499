import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logOut } from "../../store/actions/authActions";
import logo from "../../images/main-logo.svg";
import SideNav from "react-simple-sidenav";
import { Icon, Spin, message } from "antd";
import { url } from "../../config";
import MobileNavItems from "./MobileNavItems";
import queryString from 'query-string';
import "./Wrapper.scss";

class Wrapper extends Component {

    state = {
        showNav: false
    }

    toggleSideNav = () => {
        this.setState({
            showNav: !this.state.showNav
        })
    }

    logOut = () => {
        const token = localStorage.getItem("token")
        this.props.logOut(token);
    }

    warningMessage = () => {
        message.warning('Payment process was canceled');
    };

    successMessage = () => {
        return message.success('Payment was successful');
    };

    componentDidMount() {
        let params = queryString.parse(this.props.location.search);

         if(params.payment === "1") {
            this.successMessage();
        }

        if(params.payment === "0") {
            this.warningMessage();
        }
    }

    render() {

        const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
        let renderUserName = <h4><Spin indicator={antIcon} /></h4>;
        if (this.props.userData !== undefined) {
            renderUserName = <h4>Welcome, <b>{this.props.userData.name}</b></h4>
        }

        let verificationLinkMembership = null;
        if (this.props.userData && this.props.userData.verification_step_1 !== undefined && this.props.userData.verification_step_1) {
            verificationLinkMembership = (
                <>
                    <Link to="/verification">Membership</Link>
                </>
            )
        }

        let userCountry = null;
        if (this.props.userData && this.props.userData.country === null) {
            userCountry = <h3>My Account</h3>
        } else if (this.props.userData && this.props.userData.country !== null && this.props.userData.country !== undefined) {
            userCountry = <h3>{this.props.userData.country.full_country} / My Account</h3>
        }

        return (
            <>
                <div className="mobile-side-nav">
                    <div className="mobile-header">
                        <Icon type="menu-unfold" onClick={this.toggleSideNav} />
                        {renderUserName}
                        <div className="clearfix"></div>
                    </div>
                    <SideNav
                        showNav={this.state.showNav}
                        onHideNav={() => this.setState({ showNav: false })}
                        title="Jane Verde"
                        navStyle={{ background: '#ffffff', maxWidth: "250px" }}
                        style={{zIndex: 9999999999}}
                    >
                        <MobileNavItems logOut={this.logOut} userData={this.props.userData} />
                    </SideNav>
                </div>

                <div id="wrapper">
                    <div className="main-wrapper">
                        <div className="left-content">
                            <div className="left-content__inner-wrap">
                                <a href={`${url}`} target="_blank">
                                    <img src={logo} alt="Jane Verde - Main Logo" />
                                </a>
                                <div className="nav-links">
                                    <Link to="/">Home</Link>
                                </div>

                                <div className="nav-links">
                                    <Link to="/post">Create a Listing</Link>
                                    {verificationLinkMembership}
                                </div>

                                <div className="nav-links">
                                    <Link to="/">Help / FAQ</Link>
                                    <a href={`${url}/privacy-policy`} target="_blank">Privacy Policy</a>
                                    <a href={`${url}/privacy-policy`} target="_blank">Terms of Use</a>
                                    <Link to="/">Avoid Scams & Fraud</Link>
                                </div>
                                <div className="nav-links">
                                    <Link to="/settings">Change Password</Link>
                                </div>
                                <div className="nav-links">
                                    <a onClick={this.logOut}>Log Out</a>
                                </div>
                            </div>
                        </div>

                        <div className="right-content">
                            <div className="top-header-breadcrumb">
                                {userCountry}
                                {renderUserName}
                                <div className="clearfix"></div>
                            </div>
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}




const mapStateToProps = (state) => {
    return {
        userData: state.auth.userData
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        logOut: (token) => dispatch(logOut(token))
    }
};

Wrapper = withRouter(Wrapper);
export default connect(mapStateToProps, mapDispatchToProps)(Wrapper);
import React, {Component} from "react";
import ImageUploader from 'react-images-upload';
import "./Uploader.scss";

class Uploader extends Component{
    state = {
        pictures: []
    };

    onDrop = (picture, files) => {
        this.setState({
            pictures: picture
        });

        this.props.files(files);
    };


    render(){

        const options = {
            withIcon: true,
            buttonText: "Choose images",
            onChange: this.onDrop,
            imgExtension: ['.jpg', '.png', '.jpeg'],
            maxFileSize: 10242880,
            withPreview: true,
            singleImage: true,
            fileTypeError: "File type not supported",
            label: "Max file size: 10mb, accepted: jpeg|jpg|png",
            ...this.props.propOptions
        }

        return(
            <>
                <div className="form-grp text-center">
                    <ImageUploader
                      {...options}
                    />
                </div>
            </>
        )
    }
}

export default Uploader;
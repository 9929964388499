import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { signIn } from "../../store/actions/authActions";
import { authError } from "../../store/actions/authActions";
import { Button, Alert, Icon } from "antd";
import logo from "../../images/main-logo.svg";
import {url} from "../../config";
import "./SignIn.scss";

class SignIn extends Component {

    state = {
        signInInfo: {
            email: "",
            password: ""
        }
    };

    signIn = (event) => {
        event.preventDefault();
        const email = this.state.signInInfo.email;
        const password = this.state.signInInfo.password;

        this.props.signIn({ email, password });
    };

    onChangeHandler = (event) => {
        this.setState(prevState => ({
            signInInfo: {
                ...prevState["signInInfo"],
                [event.target.name]: event.target.value.trim()
            }
        }))
    };

    onKeyUpHandler = (event) => {
        if (event.keyCode === 13) {
            this.signIn(event);
        }
    }

    alertMessage = () => {
        let alert = null;

        if (this.props.authErrors !== null && (this.state.signInInfo.email === "" || this.state.signInInfo.password === "")) {
            alert = <Alert
                type="error"
                message={"E-mail address and password required"}
                style={{ marginBottom: "20px" }}
                banner={true} onClose={this.closeAlert}
                closable={true}
            />;
        } else if (this.props.authErrors) {
            alert = <Alert
                type="error"
                message={this.props.authErrors}
                style={{ marginBottom: "20px" }}
                banner={true} onClose={this.closeAlert}
                closable={true}
            />;
        } else if (this.props.authErrors === null) {
            alert = null
        }

        return alert;
    };

    closeAlert = () => {
        this.props.authError();
    };

    render() {

        if (this.props.isAuthenticated) {
            return <Redirect to="/dashboard" />
        }

        return (
            <>
                <div className="text-center auth-logo">
                    <a href={url}><img src={logo} alt="Jane Verde - Main Logo" /></a>
                </div>
                <div className="form">
                    <h2><Icon type="import" style={{ marginRight: "15px", fontSize: "21px" }} />Sign In</h2>
                    <div className="form-grp">
                        <label>E-mail address <span>(*)</span></label>
                        <input
                            type="email"
                            name="email"
                            value={this.state.email}
                            onChange={(event) => { event.persist(); this.onChangeHandler(event) }}
                            onKeyUp={(event) => this.onKeyUpHandler(event)}
                            required
                        />
                    </div>
                    <div className="form-grp">
                        <label>Password <span>(*)</span></label>
                        <input
                            type="password"
                            name="password"
                            value={this.state.password}
                            onChange={(event) => { event.persist(); this.onChangeHandler(event) }}
                            onKeyUp={(event) => this.onKeyUpHandler(event)}
                            required
                        />
                    </div>
                    <div className="form-grp text-center">
                        {this.alertMessage()}
                        <Button
                            size="large"
                            htmlType="submit"
                            type="primary"
                            loading={this.props.iconIsLoading}
                            onClick={(event) => this.signIn(event)}
                        >
                            LOGIN
                    </Button>
                    </div>
                    <div className="form-grp text-center">
                        <p className="already-account">You don't have an account, <Link to="/signup">click here</Link></p>
                        <p className="already-account" style={{ marginTop: "-10px" }}><Link to="/forgot-password">Forgot Password ?</Link></p>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.authenticated,
        authErrors: state.auth.authError,
        iconIsLoading: state.auth.loading
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (credentials) => dispatch(signIn(credentials)),
        authError: () => dispatch(authError())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
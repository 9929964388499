const initialState = {
    authError: null,
    authenticated: false,
    userData: [],
    globalLoader: false,
    created: false
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GLOBAL_LOADING":
            return {
                ...state,
                globalLoader: true
            }
        case "AUTHENTICATED":
            return {
                ...state,
                authError: null,
                authenticated: action.payload
            };
        case "SIGNIN_SUCCESS":
            return {
                ...state,
                authError: null,
                authenticated: true,
                loading: false
            };
        case "SIGNIN_ERROR":
            return {
                ...state,
                authError: action.authError,
                loading: false
            };
        case "BUTTON_LOADING":
            return {
                loading: true
            }
        case "SIGNUP_SUCCESS":
            return {
                ...state,
                authError: null,
                loading: false,
                created: true
            };
        case "SIGNUP_ERROR":
            return {
                ...state,
                authError: action.payload,
                loading: false
            };
        case "SIGNOUT_SUCCESS":
            return {
                ...state,
                authError: null,
                authenticated: false,
                globalLoader: false
            };
        case "USER_LOGGED":
            return {
                ...state,
                userData: action.payload,
            };
        case "AUTH_TOKEN":
            return {
                ...state,
                authenticated: false,
            }
        default:
            return state;
    }
};

export default authReducer;
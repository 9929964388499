import React, { Component } from "react";
import { Select } from "antd";

const { Option, OptGroup } = Select;

class Categories extends Component {

    state = {
        product_category: "",
    }

    handleSelectChange = (value, name, option) => {
        console.log(option.props.children);
        this.setState({
            [name]: value,
        })
        this.props.categories(value, name);
    };

    // selectHandler = (event,option) => {
    //     console.log(option);
    // }

    render() {
        return (
            <>
                <Select required onChange={(event, option) => this.handleSelectChange(event, "product_category", option)}
                    name="product_category" allowClear={true} placeholder={this.props.placeholder}>
                    <OptGroup label="For Sale">
                        <Option value="4296">Biomass</Option>
                        <Option value="6581">Concentrates</Option>
                        <Option value="4617">Retail Products</Option>
                        <Option value="4537">Grow Equipment / Supplies</Option>
                        <Option value="4184">Lab Equipment Supplies</Option>
                        <Option value="2971">Promotional</Option>
                        <Option value="2073">In Search Of</Option>
                    </OptGroup>
                    <OptGroup label="Vendor Listings">
                        <Option value="2057">Farms</Option>
                        <Option value="3331">Processing</Option>
                        <Option value="8090">Concentrate Facilities</Option>
                        <Option value="4112">Ag Processing Facilities</Option>
                        <Option value="7856">Lab Testing</Option>
                        <Option value="4764">Banking</Option>
                        <Option value="9132">Insurance</Option>
                        <Option value="2528">Web / Design</Option>
                        <Option value="6000">Sales Brokers</Option>
                        <Option value="1090">Consulting</Option>
                        <Option value="7854">Equipment Manufacturers</Option>
                        <Option value="3285">Telecom</Option>
                        <Option value="9121">Labor</Option>
                        <Option value="5777">Marketing / Advertising</Option>
                        <Option value="4910">Attorney</Option>
                        <Option value="8788">Logistics / Trucking</Option>
                        <Option value="5981">Equipment Rental</Option>
                        <Option value="4715">Points of Sale</Option>
                        <Option value="4644">Other</Option>
                    </OptGroup>
                    <OptGroup label="Misc">
                        <Option value="2572">Events / Promotional</Option>
                        <Option value="6095">General</Option>
                        <Option value="2724">Groups / Activities</Option>
                        <Option value="3824">Groups / Clubs / Memberships</Option>
                    </OptGroup>
                    <OptGroup label="Business / Real Estate">
                        <Option value="3668">Business For Sale</Option>
                        <Option value="5266">Commercial For Rent</Option>
                        <Option value="2152">Commercial For Sale</Option>
                        <Option value="6150">Investment Opportunities</Option>
                        <Option value="2340">Land For Sale</Option>
                    </OptGroup>
                    <OptGroup label="Jobs">
                        <Option value="2350">Admin / Executive</Option>
                        <Option value="4111">Agriculture</Option>
                        <Option value="5911">Distribution</Option>
                        <Option value="6509">Laboratory</Option>
                        <Option value="6762">Other / General</Option>
                        <Option value="3043">Processing</Option>
                        <Option value="6999">Sales / Marketing</Option>
                        <Option value="5191">All</Option>
                    </OptGroup>
                </Select>
            </>
        )
    }
}

export default Categories;
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { userInfo } from "../../store/actions/authActions";
import { Skeleton } from 'antd';
import _ from "lodash";
import { connect } from "react-redux";
import ListingsTabs from "../widgets/tabs/ListingsTab";
import { url } from "../../config";
import "./Listings.scss";
import $ from "jquery";

class Listings extends Component {

    state = {
        products: []
    }

    componentDidMount() {
        const token = localStorage.getItem("token");
        const that = this;

        $.ajax({
            type: "GET",
            dataType: "json",
            url: `${url}/api/users/list?token=${token}`,
            success: function (response) {
                const userData = response.data;
                that.props.userInfo(userData);
            },
            error: function (error) {
                console.log(error);
            }
        });
    }

    renderListings = (verification) => {
        const tabs = [
            { tab_name: "Active" },
            { tab_name: "Expired" },
            { tab_name: "Draft" },
            { tab_name: "Saved Search" }
        ];

        let listings = <Skeleton active />

        if (!_.isEmpty(verification)) {
            if (verification !== undefined && verification.verification_step_1 === 1) {
                listings =
                    (<>
                        <h1>Listings</h1>
                        <ListingsTabs tabs={tabs} />
                    </>
                    )
            } else if (verification.verification_step_1 === undefined) {
                listings = <Skeleton active />
            } else if (verification !== undefined && verification.verification_step_1 === 0) {
                listings = <>
                    <h1>Listings</h1>
                    <h4>Please <Link to="/verification-step">verify your account</Link></h4>
                    <ListingsTabs tabs={tabs} />
                </>
            }
        }

        return listings;
    }

    render() {


        if (!this.props.authenticated) {
            return <Redirect to="/signin" />
        }

        return (
            <div className="postings-wrapper">
                {this.renderListings(this.props.verification)}
                {/* {verificationMessage}
                <ListingsTabs tabs={tabs} /> */}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authenticated: state.auth.authenticated
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        userInfo: (info) => dispatch(userInfo(info))
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Listings);